// DefiPerso-Frontend\src\pages\BackOffice\objectives\UserObjectiveGetOne.js
import axios from "axios";
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ImageFetcher from '../../../components/shared/images/ImageFetcher';
import '../../../styles/objectives/userObjectiveGetOne.css';

const UserObjectiveGetOne = () => {
    const [userWithObjectives, setUserWithObjectives] = useState([]);
    const { id } = useParams();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Chargement des objectifs depuis l'API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}objectives/users/${id}/userObjectives`);
                setUserWithObjectives(response.data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [id]);


    // Fonction pour naviguer vers la page de tous les objectifs
    const handleBackToListClick = () => {
        navigate('/admin/objectives/');
    };

    // Fonction pour naviguer vers la page de tous les objectifs classés par utilisateurs
    const handleBackToUserObjectiveList = () => {
        navigate('/admin/objectives/sortUserObjective');
    };


    return (
        <div className="objectivegetone-container">
            <h1 className="objectivegetone-title">Détails des objectifs d'un utilisateur</h1>

            {loading ? (
                <p>Chargement...</p>
            ) : error ? (
                <p>Erreur: {error}</p>
            ) : (
                <div className="objectivegetone-card">
                    <p className="objectivegetonebyuser-title"> {userWithObjectives.firstname} {userWithObjectives.lastname.toUpperCase()}</p>

                    <div className="objectivegetonebyuser-card-text">
                        <div>
                            <p className="objectivegetone-details">ID : {userWithObjectives.id_user}</p>
                            <p className="objectivegetone-details">Pseudo : {userWithObjectives.username}</p>
                            <p className="objectivegetone-details">Type de compte : {userWithObjectives.account_type}</p>
                            <p className="objectivegetone-details">Email du tuteur : {userWithObjectives.tutor_email}</p>
                            <p className="objectivegetone-details">Score : {userWithObjectives.user_score}</p>
                            <p className="objectivegetone-details">Or : {userWithObjectives.gold}</p>
                        </div>

                        <div>
                            <p className="objectivegetone-details-section">Objectif(s)</p>
                            {userWithObjectives.Objectives && userWithObjectives.Objectives.length > 0 ? (
                                <ul className="userObjectivesDetails">
                                    {userWithObjectives.Objectives.map((objective, index) => (
                                        <li key={index}>
                                            <p className="userObjectivegetone-title">{objective.objective_title}</p>
                                            <div>
                                                <p>Recurrence : {objective.UserObjective.recurrence}</p>
                                                <p>Statut : {objective.UserObjective.objective_state}</p>
                                            </div>
                                            <p>Catégorie : {objective.objective_category}</p>
                                            <p>Description : {objective.objective_description}</p>
                                            <p className="objectivegetone-details">Difficulté : {objective.objective_difficulty_level}</p>
                                            <p className="objectivegetone-details">Gain d'Or : {objective.objective_gold_reward}</p>

                                            {/* Boucle pour afficher les items associés */}
                                            <p>Gains d'Item(s) :
                                                {objective.Items && objective.Items.length > 0 ? (
                                                    <ul className="itemDetails">
                                                        {objective.Items.map((item, itemIndex) => (
                                                            <li key={itemIndex}>
                                                                <p className="objectivegetone-details">{item.name}</p>
                                                                <div className="objectivegetone-details-img"> <ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}items/images`} imageName={item.image}/></div> 
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <p className="objectivegetone-details">Aucun item associé.</p>
                                                )}
                                            </p>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>Aucun objectif trouvé pour cet utilisateur.</p>
                            )}
                        </div>
                    </div>
                    <div className="sort-objectives-with-users">
                        <button className="objectivesgetall-sort-btn" onClick={handleBackToListClick}>Retour à la liste des objectifs</button>
                        <button className="objectivesgetall-sort-btn" onClick={handleBackToUserObjectiveList}>Retour à la liste des objectifs classés par Utilisateurs</button>
                    </div>
                </div>
            )}
        </div>
    );
};


export default UserObjectiveGetOne;