import React from 'react';
import '../../../styles/teams/teamsForm.css';

const TeamsForm = ({ name, setName, role, setRole, teamScore, setTeamScore, teamLogo, setTeamLogo, imageFile, setImageFile }) => {

    const previewImage = (event) => { // Fonction pour prévisualiser l'image après sa sélection
        const preview = document.getElementById('preview');
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
            preview.src = e.target.result;
            preview.style.display = 'block';
        };

        reader.readAsDataURL(file);
    };

    return ( // Corps du formulaire
        <div className="teamsform-container">
            <input
                type="text"
                placeholder="Nom de l'équipe"
                onChange={(e) => setName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))} // Met en majuscule la 1re lettre et laisse le reste des caractères tels quels
                value={name}
                required
                className="teamsform-input"
            />
            <input
                type="number"
                placeholder="Score de l'équipe"
                onChange={(e) => setTeamScore(e.target.value)}
                value={teamScore}
                required
                className="teamsform-input"
            />
            <label htmlFor="image" className="teamsform-label">Logo</label>
            <input
                name="image"
                type="file"
                accept="image/*"
                onChange={(e) => {
                    previewImage(e);
                    setTeamLogo(e.target.value);
                    setImageFile(e.target.files[0]);
                }}
                className="teamsform-input"
            // required (non required car pour l'update, pas besoin de changer l'image ?)
            />
            <img id="preview" src="" alt="Prévisualisation" className="teamsform-preview" />
            <button aria-label="Valider l'équipe" type="submit" className="teamsform-button">Valider l'équipe</button>
        </div>
    );
};

export default TeamsForm;