import bcrypt from "bcryptjs";

/**
 *
 * hash du password
 * @param { string } password
 * @returns { string } password hasher
 */
export const hashPassword = async (password) => {
    const salt = await bcrypt.genSalt(10);
    // Plus le nombre est élevé, plus le hachage sera sécurisé,
    // mais prendra plus de temps
    const hashedPassword = await bcrypt.hash(password, salt);
    return hashedPassword;
};

/**
 * compare les password
 * @param { string } password
 * @param { string } hashedPassword
 * @returns { boolean }
 */
export const comparePassword = async (password, hashedPassword) => {
    const match = await bcrypt.compare(password, hashedPassword);
    return match;
};
