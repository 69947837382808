// App.js
import React from 'react';
import './App.css';
import UserPages from "./routes/users/UserPages";
import QuestPanel from './routes/quests/QuestPanel';
import MenuDashBoardBtSections from "./components/public/MenuDashBoard/MenuDashBoardBtSections";
import Objectives from './routes/objectives/Objectives.js';
import Layout from "./components/public/Layout/Layout";
import TeamsPage from './routes/teams/TeamsPage';
import ItemNavigation from "./routes/items/ItemNavigation";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/FrontOffice/HomePage";
import InscriptionPage from "./pages/FrontOffice/user/InscriptionPage";
import ProtectedRoute from './routes/ProtectedRoute';
import UnauthorizedPage from './pages/FrontOffice/UnauthorizedPage';
import NotFoundPage from './pages/FrontOffice/NotFoundPage';
import Header from './components/public/Header';
import Footer from './components/public/Footer';
import HomePageUser from './pages/FrontOffice/HomePageUser.js';
import TeamQuestsFO from './pages/FrontOffice/teamQuest/TeamQuestsFO.js';
import StorePage from "./pages/FrontOffice/store/StorePage";
import InventoryPage from "./pages/FrontOffice/user/InventoryPage";


function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    {/* Affiche la page de connexion sur la racine */}
                    <Route path="/" element={<HomePage />} />
                    <Route path="/register" element={<InscriptionPage />} />
                    <Route path="/unauthorized" element={<UnauthorizedPage />} />

                    {/* --- Section Front Office --- */}
                    <Route path="/user/*" element={<FrontOfficeLayout />} />


                    {/* --- Section Admin protégée --- */}
                    <Route element={<ProtectedRoute allowedRoles={['Admin']} />}>
                        <Route path="/admin/*" element={<Layout />}>
                            <Route index element={<MenuDashBoardBtSections />} />
                            <Route path="users/*" element={<UserPages />} />
                            <Route path="quests/*" element={<QuestPanel />} />
                            <Route path="objectives/*" element={<Objectives />} />
                            <Route path="teams/*" element={<TeamsPage />} />
                            <Route path="items/*" element={<ItemNavigation />} />
                        </Route>
                    </Route>

                    {/* Route 404 pour les chemins non définis */}
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

// Composant de mise en page pour le front office
const FrontOfficeLayout = () => {
    return (
        <div className="front-office">
            <Header />
            <main className="content">  {/* Style dans index.css */}
                <Routes>
                    <Route path="/home" element={<HomePageUser />} />
                    <Route path="/home/team/quests" element={<TeamQuestsFO />} />
                    <Route path='/store/*' element={<StorePage />} />
                    <Route path='/inventory/*' element={<InventoryPage />} />
                </Routes>
            </main>
            <Footer />
        </div>
    );
};

export default App;