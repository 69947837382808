// DefiPerso-Frontend\src\routes\objectives\Objectives.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ObjectiveCreateForm from '../../components/forms/objectives/ObjectiveCreateForm';
import ObjectivesGetAll from '../../pages/BackOffice/objectives/ObjectivesGetAll';
import ObjectiveUpdateForm from '../../components/forms/objectives/ObjectiveUpdateForm';
import ObjectiveGetOne from '../../pages/BackOffice/objectives/ObjectiveGetOne';
import ObjectivesSortByUsers from '../../pages/BackOffice/objectives/ObjectivesSortByUsers';
import UserObjectiveGetOne from '../../pages/BackOffice/objectives/UserObjectiveGetOne';
import UserLinkToObjective from '../../pages/BackOffice/objectives/UserLinkToObjective';


const Objectives = () => {
    return (
        <Routes>
            <Route path="/" element={<ObjectivesGetAll />} />
            <Route path="/create" element={<ObjectiveCreateForm />} />
            <Route path="/:id/edit" element={<ObjectiveUpdateForm />} />
            <Route path="/:id/details" element={<ObjectiveGetOne />} />
            <Route path="/sortUserObjective" element={<ObjectivesSortByUsers />} />
            <Route path="/:id/detailsUserObjective" element={<UserObjectiveGetOne />} />
            <Route path="/:id_user/LinkUserObjective" element={<UserLinkToObjective />} />
        </Routes>
    );
};



export default Objectives;