import {useState, useEffect} from "react";
import axios from "axios";

const useUserInventory = (id) => {
    const [userInventory, setUserInventory] = useState([]);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [selectedItemDetails, setSelectedItemDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchInventory = async () => {
            try {
                const result = await axios.get(`${process.env.REACT_APP_API_URL}users/${id}/inventory`);
                setUserInventory(result.data);
                if (result.data.length > 0) {
                    const firstItem = result.data[0];
                    setSelectedItemId(firstItem.id_item);
                    setSelectedItemDetails(firstItem);
                }
            } catch (error) {
                console.error("Error fetching users by ID:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchInventory();
    }, [id]);

    const handleDetails = (itemId) => {
        const itemDetails = userInventory.find(item => item.id_item === itemId);
        setSelectedItemId(itemId);
        setSelectedItemDetails(itemDetails);
    };

    const handleDeleteItem = async (itemId) => {
        if (!window.confirm('Voulez-vous vraiment supprimer cet objet de votre inventaire?')) {
            return;
        }
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}users/${id}/inventory/${itemId}/delete`);
            alert('Objet supprimé avec succès');
            setUserInventory(prevInventory => prevInventory.filter(item => item.id_item !== itemId));
            setSelectedItemDetails(null);
        } catch (error) {
            console.error("Error deleting items from inventory:", error);
            setError(error.message);
        }
    };

    return {
        userInventory,
        selectedItemId,
        selectedItemDetails,
        loading,
        error,
        handleDetails,
        handleDeleteItem,
        id_user: id
    };
};

export default useUserInventory;
