import React from "react";

/**
 *
 * @param newLimit callback - modification de la limite d'element a afficher selon selecteur
 * @param options array - elements du selecteur
 * @returns {Element}
 */
const PaginateLimitElement = ({newLimit, options}) => {

    return (
        <div className="Pagination-Limit">
            <label>Nombre a afficher par page : </label>
            <select onChange={(e) => {newLimit(Number(e.target.value))}}>
                {options.map((option,index) => {
                    return(
                    <option value={option} key={index}>{option}</option>
                    )
                })}
            </select>
        </div>
)

}
export default PaginateLimitElement;