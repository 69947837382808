import React from "react";
import InventoryGrid from "../../../components/shared/InventoryGrid";
import '../../../styles/users/UserInventory.css';
import useUserInventory from "../../../components/shared/useUserInventory";
import {jwtDecode} from "jwt-decode";

/**
 * InventoryPage - Composant pour gérer et afficher l'inventaire de l'utilisateur.
 *
 * @returns {JSX.Element} Le composant InventoryPage.
 */
const InventoryPage = () => {
    const token = sessionStorage.getItem('token');
    const id = jwtDecode(token).user.id_user;
    const {userInventory, selectedItemDetails, loading, error, handleDetails, handleDeleteItem} =
        useUserInventory(id);


    return (
        <div className="inventory-page">
            {error && <p className="error-message">{error}</p>}
            {loading ? (
                <p>Chargement...</p>
            ) : (
                <InventoryGrid
                    userInventory={userInventory}
                    selectedItemDetails={selectedItemDetails}
                    handleDetails={handleDetails}
                    handleDeleteItem={handleDeleteItem}
                />
            )}
        </div>
    )
}

export default InventoryPage;
