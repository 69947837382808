import React, {useEffect, useState} from "react";

const SearchBar = ({output}) => {
    const [search, setSearch] = useState("name_ASC");
    const [searchInput, setSearchInput] = useState("");

    useEffect(() => {
        output({
            searchTerm: searchInput,
            sort: search,
        });
    },[search, searchInput])

    return(
        <>
        <div className="Search-Field">
            <select onChange={(e) => {
                setSearch(e.target.value);
            }}>
                <option value='name_ASC'>nom (Croissant)</option>
                <option value='name_DESC'>nom (Decroissant)</option>
                <option value='price_ASC'>Prix (Croissant)</option>
                <option value='price_DESC'>Prix (Decroissant)</option>
            </select>

            {!search.includes('price') && (
                <div>
                <label>recherche : </label>
                <input onChange={(e)=>{
                    setSearchInput(e.target.value);
                }}/>
                </div>
            )}

        </div>
        </>
    )

}
export default SearchBar;