import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ImageFetcher from '../../../components/shared/images/ImageFetcher';
import { useNavigate } from 'react-router-dom';
import '../../../styles/teams/teamsStyles.css';

const TeamQuestsFetchAll = () => {
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortOrder, setSortOrder] = useState({ key: '', order: 'asc' });
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}teams/quests/all`);
                setTeams(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des données :", error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    if (loading) return <p>Chargement des données...</p>;
    if (error) return <p>Erreur : {error.message}</p>;

    // Définir une fonction pour obtenir l'index du niveau de difficulté (1 à 6)
    const getDifficultyIndex = (level) => {
        switch (level) {
            case 'Très facile':
                return 1;
            case 'Facile':
                return 2;
            case 'Moyen':
                return 3;
            case 'Difficile':
                return 4;
            case 'Très difficile':
                return 5;
            case 'Spécial_X':
                return 6;
            default:
                return 0;
        }
    };

    // Fonction pour obtenir la couleur associée à chaque niveau de difficulté
    const getDifficultyColor = (level) => {
        switch (level) {
            case 'Très facile':
                return '#00FF00'; // Vert
            case 'Facile':
                return '#7FFF00'; // Vert clair
            case 'Moyen':
                return '#FFD700'; // Jaune
            case 'Difficile':
                return '#FF8C00'; // Orange
            case 'Très difficile':
                return '#FF4500'; // Rouge foncé
            case 'Spécial_X':
                return '#4B0082'; // Indigo (couleur spéciale)
            default:
                return '#D3D3D3'; // Gris pour les niveaux inconnus
        }
    };

    // Fonction pour afficher une jauge graduée en 6 niveaux, chaque niveau remplit en fonction de la difficulté
    const renderDifficultyGauge = (level) => {
        const index = getDifficultyIndex(level);
        const color = getDifficultyColor(level);

        return (
            <div className="difficulty-gauge-container">
                {[...Array(6)].map((_, i) => (
                    <div
                        key={i}
                        className="gauge-segment"
                        style={{
                            backgroundColor: i < index ? color : '#E0E0E0', // Remplit les segments jusqu'à l'index correspondant
                        }}
                    />
                ))}
            </div>
        );
    };

    // Fonction de suppression d'une quête de l'équipe
    const handleDelete = async (idTeam, idQuest) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer cette quête ?")) {
            try {
                const response = await axios.delete(`${process.env.REACT_APP_API_URL}teams/delete/${idTeam}/quest/${idQuest}`);
                if (response.status === 200) {
                    window.location.reload();
                } else {
                    alert("Erreur lors de la suppression de la quête.");
                }
            } catch (error) {
                console.error("Erreur lors de la suppression de la quête :", error);
                setError(error);
            }
        }
    };

    // Fonction d'édition d'une quête de l'équipe
    const handleEdit = (idTeam, idQuest, currentState) => {
        const newState = currentState === 'En cours' ? 'Terminée' : 'En cours';

        axios.put(`${process.env.REACT_APP_API_URL}teams/edit/${idTeam}/quest/${idQuest}`, { team_quest_state: newState })
            .then((response) => {
                if (response.status === 200) {
                    window.location.reload();
                } else {
                    alert("Erreur lors de la mise à jour du statut de la quête.");
                }
            })
            .catch((error) => {
                console.error("Erreur lors de la mise à jour du statut de la quête :", error);
                setError(error);
            });
    };

    // Fonction d'affichage de l'état de la quête avec bascule au clic
    const renderQuestState = (teamId, questId, currentState) => {
        return (
            <span
                className="quest-state"
                data-tooltip="Changer le statut"
                onClick={() => handleEdit(teamId, questId, currentState)}
                style={{ cursor: 'pointer' }}
            >
                {currentState === 'En cours' ? <>⌛<br />En cours</> : <>✔️<br />Terminée</>}
            </span>
        );
    };

    const sortQuests = (key) => {
        const order = sortOrder.key === key && sortOrder.order === 'asc' ? 'desc' : 'asc';

        const sortedTeams = [...teams].map(team => {
            return {
                ...team,
                Quests: [...team.Quests].sort((a, b) => {
                    if (key === 'id_quest') {
                        return order === 'asc' ? a.id_quest - b.id_quest : b.id_quest - a.id_quest;
                    } else if (key === 'team_quest_state') {
                        const stateA = a.Team_Quest.team_quest_state || '';
                        const stateB = b.Team_Quest.team_quest_state || '';
                        return order === 'asc' ? stateA.localeCompare(stateB) : stateB.localeCompare(stateA);
                    } else {
                        return 0;
                    }
                })
            };
        }).sort((a, b) => {
            if (key === 'id_team') {
                return order === 'asc' ? a.id_team - b.id_team : b.id_team - a.id_team;
            }
            return 0;
        });

        setTeams(sortedTeams);
        setSortOrder({ key, order });
    };

    const filteredTeams = teams.filter((team) => {
        const teamMatches = team.name.toLowerCase().includes(searchTerm.toLowerCase());

        const questsMatch = team.Quests.some((quest) =>
            quest.quest_title.toLowerCase().includes(searchTerm.toLowerCase())
        );

        return teamMatches || questsMatch;
    });

    const handleRefresh = () => {
        window.location.reload();
    }

    return (
        <div className="team-quests-fetch-container">
            <h1 className="teamsmanagement-title">Liste de toutes les quêtes d'équipe</h1>
            <div className="teamsform-container large">

                <div className="teamstyles-search-and-sort-container">
                    <input
                        type="text"
                        className="search-bar"
                        placeholder="Rechercher par équipe..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />

                    <div className="teamstyles-sort-buttons">
                        <button aria-label="Trier par équipe" onClick={() => sortQuests('id_team')}>
                            ⇵ #équipe {sortOrder.key === 'id_team' && (sortOrder.order === 'asc' ? '⬆️' : '⬇️')}
                        </button>
                        <button aria-label="Trier par quête" onClick={() => sortQuests('id_quest')}>
                            ⇵ #quête {sortOrder.key === 'id_quest' && (sortOrder.order === 'asc' ? '⬆️' : '⬇️')}
                        </button>
                        <button aria-label="Trier par statut" onClick={() => sortQuests('team_quest_state')}>
                            ⇵ statut {sortOrder.key === 'team_quest_state' && (sortOrder.order === 'asc' ? '⬆️' : '⬇️')}
                        </button>
                        <button className="tooltip" aria-label="Rafraîchir la page" onClick={handleRefresh} data-tooltip="Rafraîchir la page">
                            ↻
                        </button>
                    </div>
                </div>

                {filteredTeams.length === 0 ? (
                    <div className="teamstyles-sort-buttons"><p>Aucune quête d'équipe trouvée. <button onClick={() => { navigate('/admin/teams/quests/new') }}>+ Ajouter une équipe</button></p></div>

                ) : (
                    <table className="quests-table">
                        <thead>
                            <tr>
                                <th className="teamFirst" colSpan={2}>Équipe</th>
                                <th className="questFirst" colSpan={6}>Quête</th>
                            </tr>
                            <tr>
                                <th className="teamSecond">Nom</th>
                                <th className="teamSecond">Logo</th>
                                <th className="questSecond">Titre</th>
                                <th className="questSecond">Icône</th>
                                <th className="questSecond">Statut</th>
                                <th className="questSecond">Niveau de difficulté</th>
                                <th className="questSecond" colSpan={2}>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredTeams.map((team) => (
                                team.Quests.length > 0 ? (
                                    team.Quests.map((quest) => (
                                        <tr key={quest.id_quest}>
                                            <td className="textAlignLeft"><em className="teamstyles-grey">#{team.id_team}</em> <strong>{team ? team.name : "Équipe non trouvée"}</strong></td>
                                            <td><ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}teams/images`} imageName={team.team_logo} /></td>
                                            <td className="textAlignLeft"><em className="teamstyles-grey">#{quest.id_quest}</em> <strong>{quest.quest_title}</strong></td>
                                            <td><ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}quests/images`} imageName={quest.quest_icon} /></td>
                                            <td>{renderQuestState(team.id_team, quest.id_quest, quest.Team_Quest.team_quest_state)}</td>
                                            <td clasName="cell-center">{renderDifficultyGauge(quest.quest_difficulty_level)} {quest.quest_difficulty_level}</td>
                                            <td>{quest.quest_description}</td>
                                            <td>
                                                <button aria-label="Supprimer la quête d'équipe" className="buttonDelete tooltip" onClick={() => handleDelete(team.id_team, quest.id_quest)} data-tooltip="Supprimer la quête d'équipe">🗑</button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    null
                                )
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default TeamQuestsFetchAll;