import React, {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import '../../../styles/users/userUpdateById.css';
import ButtonGenerique from "../../shared/ButtonUser/ButtonGenerique";

/**
 *
 * @returns {Element}
 */
const UserUpdateById = () => {
    const [user, setUser] = useState({});
    const {id} = useParams();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [account_type, setAccountType] = useState('');
    const [tutor_email, setTutorEmail] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [user_score, setUserScore] = useState('');
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [gender, setGender] = useState('');
    const [pose, setPose] = useState('');
    const [face, setFace] = useState('');
    const [body_shape, setBodyShape] = useState('');
    const [skin_color, setSkinColor] = useState('');
    const [id_team, setIdTeam] = useState('');

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const result = await axios.get(`${process.env.REACT_APP_API_URL}users/${id}`);
                const userData = result.data[0];
                setUser(userData ?? undefined);
                setEmail(userData.email ?? undefined);
                setFirstName(userData.firstname ?? undefined);
                setLastName(userData.lastname ?? undefined);
                setAccountType(userData.account_type ?? undefined);
                setTutorEmail(userData.tutor_email ?? undefined);
                setBirthdate(userData.birthdate ?? undefined);
                setUserScore(userData.user_score ?? undefined);
                setWeight(userData.weight ?? undefined);
                setHeight(userData.height ?? undefined);
                setGender(userData.gender ?? undefined);
                setPose(userData.pose ?? undefined);
                setFace(userData.face ?? undefined);
                setBodyShape(userData.body_shape ?? undefined);
                setSkinColor(userData.skin_color ?? undefined);
                setIdTeam(userData.id_team ?? undefined);
            } catch (error) {
                console.error("Error fetching users by ID:", error);
            }
        };
        fetchUser();
    }, [id]);

    const handleUpdateUser = async (e) => {
        // verification formulaire

        e.preventDefault();

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}users/${id}/edit`, {
                username: user.username,
                password: user.password,
                email,
                firstname,
                lastname,
                account_type,
                tutor_email,
                birthdate,
                user_score,
                weight,
                height,
                gender: gender ? gender : null,
                pose,
                face,
                body_shape,
                skin_color,
                id_team,
            });
            console.log(response.data);
            alert('User updated successfully');
            navigate("/admin/users");
        } catch (error) {
            console.error('Error updating users:', error.config.data);
            alert('Failed to update users');
        }
    };

    if (!user.id_user) {
        return <div>Loading...</div>;
    }

    return (
        <div className="userupdate-container">
            <h2 className="userupdate-title">Update User by ID: {id}</h2>
            <form onSubmit={handleUpdateUser} className="userupdate-form">
                <div className="userupdate-form-section">
                    <div className="userupdate-line userupdate-read-only">
                        <p>Id User : {user.id_user}</p>
                        <input type="text" value={user.id_user} readOnly/>
                    </div>
                    <div className="userupdate-line userupdate-read-only">
                        <p>Id teams :</p>
                        <input type="text" value={user.id_teams} readOnly/>
                    </div>
                    <div className="userupdate-line userupdate-read-only">
                        <p>User name :</p>
                        <input type="text" value={user.username} readOnly/>
                    </div>
                    <div className="userupdate-line">
                        <p>Email :</p>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="userupdate-line">
                        <p>Firstname :</p>
                        <input
                            type="text"
                            value={firstname}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>
                    <div className="userupdate-line">
                        <p>Lastname :</p>
                        <input
                            type="text"
                            value={lastname}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                    <div className="userupdate-line userupdate-read-only">
                        <p>Account Type :</p>
                        <input type="text" value={account_type} readOnly/>
                    </div>
                    <div className="userupdate-line">
                        <p>Tutor Email :</p>
                        <input
                            type="email"
                            value={tutor_email}
                            onChange={(e) => setTutorEmail(e.target.value)}
                        />
                    </div>
                    <div className="userupdate-line">
                        <p>Birthdate :</p>
                        <input
                            type="date"
                            value={birthdate}
                            onChange={(e) => setBirthdate(e.target.value)}
                        />
                    </div>
                    <div className="userupdate-line">
                        <p>User Score :</p>
                        <input
                            type="number"
                            value={user_score}
                            onChange={(e) => setUserScore(e.target.value)}
                        />
                    </div>
                    <div className="userupdate-line">
                        <p>Weight :</p>
                        <input
                            type="number"
                            value={weight}
                            onChange={(e) => setWeight(e.target.value)}
                        />
                    </div>
                    <div className="userupdate-line">
                        <p>Height :</p>
                        <input
                            type="number"
                            value={height}
                            onChange={(e) => setHeight(e.target.value)}
                        />
                    </div>
                    <div className="userupdate-line">
                        <p>Gender :</p>
                        <select
                            onChange={(e) => {
                                setGender(e.target.value);
                            }}
                            value={gender}
                        >
                            <option value="">Aucun</option>
                            <option value="Homme">Homme</option>
                            <option value="Femme">Femme</option>
                            <option value="Autres">Autres</option>
                            <option value="Préfère ne rien répondre">Ne souhaite pas répondre</option>
                        </select>
                    </div>
                    <div className="userupdate-line">
                        <p>Pose :</p>
                        <input
                            type="text"
                            value={pose}
                            onChange={(e) => setPose(e.target.value)}
                        />
                    </div>
                    <div className="userupdate-line">
                        <p>Face :</p>
                        <input
                            type="text"
                            value={face}
                            onChange={(e) => setFace(e.target.value)}
                        />
                    </div>
                    <div className="userupdate-line">
                        <p>Body Shape :</p>
                        <input
                            type="text"
                            value={body_shape}
                            onChange={(e) => setBodyShape(e.target.value)}
                        />
                    </div>
                    <div className="userupdate-line">
                        <p>Skin Color :</p>
                        <input
                            type="text"
                            value={skin_color}
                            onChange={(e) => setSkinColor(e.target.value)}
                        />
                    </div>
                </div>
                <div className="userupdate-buttons">
                    <ButtonGenerique
                        type="submit"
                        name="Update data"
                        onClique={handleUpdateUser}
                    />
                    <ButtonGenerique
                        name="Back"
                        onClique={() => {
                            navigate("/admin/users");
                        }}
                    />
                </div>
            </form>
        </div>
    );
}

export default UserUpdateById;
