/**
 *
 * @param rawDataList array - tableau de donnee a paginer
 * @param limit int - nombre limite d'element a afficher par page
 * @returns {{data: *[], totalPages: number}}
 */
export const PaginateData = (rawDataList, limit) => {

    const totalPages = Math.ceil(rawDataList.length / limit);
    let index = 0;
    let paginatedData = [];

    for (let i = 0; i < totalPages; i++) {
        paginatedData[i] = [];
        for (let j = 0; j < limit; j++) {
            if(index < rawDataList.length){
                paginatedData[i].push(rawDataList[index]);
                index ++;
            }
        }
    }


    return {
        totalPages: totalPages,
        data: paginatedData,
    }
}