import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../styles/general/homepageUser.css';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const HomePageUser = () => {
    const [quests, setQuests] = useState([]);
    const [team, setTeam] = useState([]);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Fonction pour récupérer les données de l'utilisateur connecté
        const fetchUserData = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const id_user = jwtDecode(token).user.id_user;
                if (!id_user) {
                    console.error('ID utilisateur non trouvé dans le sessionStorage');
                    return;
                }

                // Récupération des informations utilisateur
                const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}users/${id_user}`);
                setUser(userResponse.data[0]);

                // Récupération des quêtes en cours
                const questsResponse = await axios.get(`${process.env.REACT_APP_API_URL}users/${id_user}/quests-links`);
                setQuests(questsResponse.data.rows);
                console.log(questsResponse.data.rows);

                // Récupération de l'équipe de l'utilisateur (si existante)
                const teamsResponse = await axios.get(`${process.env.REACT_APP_API_URL}teams/user/${id_user}/team`);
                setTeam(teamsResponse.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
                navigate('/');
            }
        };

        fetchUserData();
    }, []);

    const handleAddQuest = () => {
        navigate('/quest/new');
    };

    const handleSeeTeamQuests = () => {
        navigate('/user/home/team/quests');
    };

    // Fonction pour déterminer la progression de la jauge en fonction de l'état de la quête
    const getQuestProgress = (questState) => {
        switch (questState) {
            case 'En cours':
                return 50;
            case 'Terminé':
                return 100;
            default:
                return 0;
        }
    };

    return (
        <div className="homepage-user">

            {/* Quêtes en cours */}
            <h1 className="homepage-title">Quêtes en cours</h1>
            {quests.length > 0 ? (
                <>
                    <ul className="quest-list">
                        {quests.filter(quest => quest.quest_state === 'En cours').slice(0, 3).map((quest) => (
                            <li key={quest.id_quest} className="quest-item"
                                onClick={() => navigate(`quest/${quest.id_quest}`)}
                            >
                                <h3><em className="grey">#{quest.id_quest}</em> {quest.quest_title}</h3>
                                <div className="progress-bar-container tooltip" data-tooltip="Voir la quête">
                                    <div
                                        className="progress-bar"
                                        style={{ width: `${getQuestProgress(quest.quest_state)}%` }}
                                    >{getQuestProgress(quest.quest_state)} %</div>
                                </div>
                            </li>
                        ))}
                    </ul>
                    {quests.filter(quest => quest.quest_state === 'En cours').length > 3 && (
                        <button aria-label="Voir toutes les quêtes en cours" className="homepage-button" onClick={() => navigate('/quests/all')}>
                            Voir toutes les quêtes en cours
                        </button>
                    )}
                </>
            ) : (
                <>
                    <p>Vous n'avez pas encore de quêtes en cours.</p>
                    <button aria-label="Ajouter une quête" className="homepage-button" onClick={handleAddQuest}>+ Ajouter une quête</button>
                </>
            )}

            {/* Résumé profil */}
            {user && <h1 className="homepage-title">Hello {user.username} !</h1>}

            {team && (
                <>
                    <p className="user-info">Équipe {team.name} <em className="grey">#{team.id_team} </em></p>
                    <button aria-label="Voir toutes les quêtes d'équipe" className="homepage-button" onClick={handleSeeTeamQuests}>Voir les quêtes d'équipe</button>
                </>
            )}

            {user && user.avatar && (
                <img
                    src={user.avatar}
                    alt={`${user.username}'s avatar`}
                    className="user-avatar"
                />
            )}
        </div>
    );
};

export default HomePageUser;