// DefiPerso-Frontend\src\components\forms\objectives\ObjectiveUpdateForm.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../../styles/objectives/objectiveUpdateForm.css';
import ObjectivesItemsGetAll from '../../shared/ObjectivesItemsGetAll';
import ImageFetcher from "../../shared/images/ImageFetcher";
import { ItemsSearchAndSort } from "../../shared/ItemsSearchAndSort";


const ObjectiveUpdateForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    // États pour les champs du formulaire
    const [updatedTitle, setUpdatedTitle] = useState('');
    const [updatedCategory, setUpdatedCategory] = useState('');
    const [updatedDescription, setUpdatedDescription] = useState('');
    const [updatedDifficulty, setUpdatedDifficulty] = useState('');
    const [updatedGoldReward, setUpdatedGoldReward] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [itemsList, setItemsList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('name_ASC');


    const handleItemChange = (itemId) => {
        setSelectedItems(prevSelected => {
            const isAlreadySelected = prevSelected.includes(itemId);
            if (isAlreadySelected) {
                // Supprime l'item s'il est déjà sélectionné
                return prevSelected.filter(id => id !== itemId);
            } else {
                // Ajoute l'item s'il n'est pas encore sélectionné
                return [...prevSelected, itemId];
            }
        });
    };

    useEffect(() => {
        // Charger les données de l'objectif à partir de l'API
        axios.get(`${process.env.REACT_APP_API_URL}objectives/${id}`)
            .then(response => {
                const { objective_title, objective_category, objective_description, objective_difficulty_level, objective_gold_reward, Items } = response.data;
                setUpdatedTitle(objective_title);
                setUpdatedCategory(objective_category);
                setUpdatedDescription(objective_description);
                setUpdatedDifficulty(objective_difficulty_level);
                setUpdatedGoldReward(objective_gold_reward);

                const selectedItemIds = Items.map(item => item.id_item);

                setSelectedItems(selectedItemIds);
            })
            .catch(error => console.error('Error loading objective:', error));
    }, [id]);


    const handleSubmit = async (e) => {
        e.preventDefault(); // Empêche le rechargement de la page
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}objectives/${id}/edit`, {
                objective_title: updatedTitle,
                objective_category: updatedCategory,
                objective_description: updatedDescription,
                objective_difficulty_level: updatedDifficulty,
                objective_gold_reward: updatedGoldReward,
                items: selectedItems,
            });

            // Redirection vers la page principale après mise à jour réussie
            navigate('/admin/objectives/');
        } catch (error) {
            console.error('Error updating objective: :', error);
        }
    };

    // Fonction pour réinitialiser l'input de recherche
    const handleResetClick = () => {
        setSearchTerm('');
    };

    // Application de la fonction de tri et recherche
    const filteredAndSortedItems = ItemsSearchAndSort(itemsList, searchTerm, sortBy);

    return (
        <div>
            <form onSubmit={handleSubmit} className="objectiveupdateform-container">
                <h1 className="objectiveupdateform-title">Modifier un objectif</h1>
                <div className="objectiveupdateform-fields">

                    <label className="objectiveupdateform-label">Titre : </label>
                    <input type="text" value={updatedTitle} onChange={e => setUpdatedTitle(e.target.value)} className="objectiveupdateform-input" />

                    <label className="objectiveupdateform-label">Catégorie : </label>
                    <select value={updatedCategory} onChange={e => setUpdatedCategory(e.target.value)}>
                        <option value="Travail / Carrière">Travail / Carrière</option>
                        <option value="Sport / Activité physique">Sport / activité physique</option>
                        <option value="Santé / Bien-être">Santé / Bien-être</option>
                        <option value="Scolarité / Apprentissage">Education / Apprentissage</option>
                        <option value="Finances">Finances</option>
                        <option value="Amis / Famille">Amis / Famille</option>
                        <option value="Loisirs / Passions">Loisirs / Passions</option>
                        <option value="Développement Personnel">Développement Personnel</option>
                        <option value="Maison / Organisation">Maison / Organisation</option>
                        <option value="Autres">Autres</option>
                    </select>

                    <label className="objectiveupdateform-label">Description : </label>
                    <textarea className="objectiveupdateform-textarea"
                        value={updatedDescription}
                        onChange={e => setUpdatedDescription(e.target.value)}
                        rows="5"
                        cols="30"
                    />

                    <label className="objectiveupdateform-label">Difficulté : </label>
                    <select value={updatedDifficulty} onChange={e => setUpdatedDifficulty(e.target.value)} className="objectiveupdateform-select">
                        <option value="Facile">Facile</option>
                        <option value="Moyen">Moyen</option>
                        <option value="Difficile">Difficile</option>
                    </select>

                    <label className="objectiveupdateform-label">Gain d'Or : </label>
                    <input className="objectiveupdateform-input" type="number" value={updatedGoldReward} onChange={e => setUpdatedGoldReward(e.target.value)} />
                </div>

                <div className="objectiveupdateform-buttons">
                    <button className="objectiveupdateform-btn" type="submit">Modifier</button>
                    <button className="objectiveupdateform-btn" type="button" onClick={() => navigate('/admin/objectives/')}>Annuler</button>
                </div>
            </form>

            <div className="objectiveupdateform-container">
                {/* Affichage de la liste des items avec recherche et tri */}
                <h2 className="titleObjH2">Liste des items disponibles</h2>

                {/* Tri */}
                <div>
                    <label className="objectiveupdateform-label">Trier par </label>
                    <select onChange={(e) => setSortBy(e.target.value)} className="sortSelect">
                        <option value='name_ASC' defaultValue>Nom</option>
                        <option value="id">ID</option>
                        <option value="category">Catégorie</option>
                        <option value="subcategory">Sous Catégorie</option>
                    </select>
                </div>


                {/* Recherche */}
                <div className="search-and-sort">
                    <label className="objectivecreateform-label">Contenant </label>
                    <input className="objectivecreateform-InputSearch" onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }} value={searchTerm} />

                    <button onClick={handleResetClick}>Réinitialiser la recherche</button>

                </div>

                {/* Affichage des items */}
                <ObjectivesItemsGetAll onItemsFetched={setItemsList} />
                {itemsList.length === 0 ? (
                    // Affichage d'un message si aucun résultat n'est trouvé
                    <p className="ObjNoResultsMessage">Aucun item disponible pour les objectifs</p>
                ) : (
                    <div className='ObjTableItems'>
                        <table>
                            <thead className="objectivesItems-thead">
                                <tr>
                                    <th>Ajouter</th>
                                    <th>ID</th>
                                    <th>Nom</th>
                                    <th>Description</th>
                                    <th>Categorie</th>
                                    <th>Sous Categorie</th>
                                    <th>Image</th>
                                    <th>Obtention</th>
                                </tr>
                            </thead>
                            <tbody className="objectivesItems-body">
                                {filteredAndSortedItems.map(item => (
                                    <tr className="ObjectivesItems-row" key={item.id_item}>
                                        <td className="td-type-1">
                                            <input
                                                type="checkbox"
                                                checked={Array.isArray(selectedItems) && selectedItems.includes(item.id_item)}  // Coche la checkbox si l'item est sélectionné
                                                onChange={() => handleItemChange(item.id_item)}
                                            />
                                        </td>
                                        <td className="td-type-1">{item.id_item}</td>
                                        <td>{item.name}</td>
                                        <td>{item.item_description}</td>
                                        <td className="td-type-1">{item.category}</td>
                                        <td className="td-type-1">{item.subcategory}</td>
                                        <td className="td-type-1"><ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}items/images`} imageName={item.image} /></td>
                                        <td className="td-type-1">{item.accessibility}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ObjectiveUpdateForm;