import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TeamsForm from '../../../components/forms/teams/TeamsForm';
import { useParams, useNavigate } from 'react-router-dom';
import '../../../styles/teams/teamsStyles.css'

const TeamsEdit = () => {
    // Récupère l'ID de l'équipe à partir des paramètres de l'URL
    const { id } = useParams();
    // Permet de naviguer vers une autre page
    const navigate = useNavigate();
    // Déclare les états pour les champs du formulaire
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [teamScore, setTeamScore] = useState('');
    const [teamLogo, setTeamLogo] = useState('');
    const [imageFile, setImageFile] = useState(null); // Ajoutez ceci

    useEffect(() => {
        // Fonction pour récupérer les données de l'équipe à partir de l'API
        const fetchTeam = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}teams/id/${id}`);
                const team = response.data;
                // Met à jour les états avec les données de l'équipe
                setName(team.name);
                setRole(team.role);
                setTeamScore(team.team_score);
                setTeamLogo(team.team_logo);
            } catch (err) {
                console.error('Error fetching team:', err);
            }
        };
        // Appelle la fonction pour récupérer les données de l'équipe
        fetchTeam();
    }, [id]); // Dépendance sur l'ID de l'équipe

    const handleEditTeam = async (e) => {
        e.preventDefault();
        try {
            // Crée un objet FormData pour envoyer les données du formulaire
            const formData = new FormData();
            formData.append('name', name);
            formData.append('role', role);
            formData.append('team_score', teamScore);

            // Vérifie si une nouvelle image est fournie, l'ajoute seulement si c'est le cas
            if (imageFile) {
                formData.append('team_logo', imageFile);
            }

            // Envoie une requête PUT pour mettre à jour les données de l'équipe
            const response = await axios.put(`${process.env.REACT_APP_API_URL}teams/edit/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Team updated successfully', response.data);
            // Redirige vers la page des équipes après la mise à jour
            navigate('/admin/teams');
        } catch (err) {
            console.error('Error updating team:', err);
        }
    };

    return (
        <form onSubmit={handleEditTeam}>
            <div className="teamsmanagement-container">
                <h1 className="teamsmanagement-title">Éditer l'équipe <em>{name}</em></h1>
                <TeamsForm
                    name={name}
                    setName={setName}
                    role={role}
                    setRole={setRole}
                    teamScore={teamScore}
                    setTeamScore={setTeamScore}
                    teamLogo={teamLogo}
                    setTeamLogo={setTeamLogo}
                    imageFile={imageFile}
                    setImageFile={setImageFile}
                />
            </div>
        </form>
    );
};

export default TeamsEdit;
