import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import CreateQuest from '../../components/forms/quests/CreateQuest.js';
import QuestById from '../../components/forms/quests/QuestById.js';
import QuestList from '../../pages/BackOffice/quests/QuestList.js';
import UpdateQuest from '../../components/forms/quests/UpdateQuest.js';
import UserQuestForm from '../../components/forms/quests/UserQuestForm.js';
import '../../styles/quests/questPanel.css';

const QuestPanel = () => {
    return (
        <div className="quest-panel-container">
            <h1 className="quest-panel-header">Quest Management</h1>
            <nav className="quest-panel-navbar">
                <ul className="quest-panel-nav-links">
                    <li>
                        <Link to="create" className="quest-panel-nav-link">Create Quest</Link>
                    </li>
                    <li>
                        <Link to="list" className="quest-panel-nav-link">Quest List</Link>
                    </li>
                    <li>
                        <Link to="update" className="quest-panel-nav-link">Update Quest</Link>
                    </li>
                    <li>
                        <Link to="quest" className="quest-panel-nav-link">Get Quest By ID</Link>
                    </li>
                    <li>
                        <Link to="userquest" className="quest-panel-nav-link">User Quest Form</Link>
                    </li>
                </ul>
            </nav>

            <Routes>
                <Route path="/create" element={<CreateQuest />} />
                <Route path="/list" element={<QuestList />} />
                <Route path="/update" element={<UpdateQuest />} />
                <Route path="/quest" element={<QuestById />} />
                <Route path="/userquest" element={<UserQuestForm />} />
                <Route path="/" element={<h2>Welcome to the Quest Management Panel!</h2>} />
            </Routes>
        </div>
    );
};

export default QuestPanel;