import React from "react";
import {NavLink} from "react-router-dom";
import '../../../styles/menus/menuSectionUsers.css';

/**
 *
 * @returns {Element}
 */
function MenuSectionUsers() {
    return (
        <>
            <h2>Menu USERS</h2>
            <nav className="menusectionusers-navbar">
                <ul className="menusectionusers-menu">
                    <li><NavLink to="/admin/users" className="menusectionusers-button">Accueil page USERS</NavLink></li>
                    <li><NavLink to="/admin/users/new" className="menusectionusers-button">Ajouter un joueur</NavLink>
                    </li>
                </ul>
            </nav>
        </>
    );
}

export default MenuSectionUsers;