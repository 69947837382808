//
// components/UsersQuestsLinks.js

import React, {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import '../../../styles/users/userQuestLinkFetchAll.css';

/**
 *
 * @returns {Element}
 */
const UsersQuestsLinks = () => {
    const navigate = useNavigate();
    const [usersQuestLink, setUserQuestLink] = useState([]);

    // useEffect pour fetcher les données au démarrage du composant et à chaque modification
    useEffect(() => {
        // fetch request récupère toutes les questLink de l'api
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}users/all/quests-links`);
                setUserQuestLink(response.data);
            } catch (error) {
                console.error('Error fetching quests:', error);
            }
        };
        fetchUsers();
    }, [])

    return (
        <div className="userquestlink-container">
            <h1 className="userquestlink-title">Quest Links</h1>

            {usersQuestLink.map((questLink, index) => (
                <div className="userquestlink-card" key={index}>
                    <h2 className="userquestlink-subtitle">Quest Link {index + 1}</h2>
                    <p>Quest ID: {questLink.id_quest}</p>
                    <p>User ID: {questLink.id_user}</p>
                    <p>Completed: {questLink.quest_status ? 'Yes' : 'No'}</p>
                </div>
            ))}

            <div className="userquestlink-button-container">
                <button className="userquestlink-button" onClick={() => navigate('/admin/questsLinks/add')}>
                    Add a new Quest Link
                </button>
            </div>
        </div>
    )
}

export default UsersQuestsLinks;