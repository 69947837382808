import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../../../styles/teams/teamsStyles.css'
import ImageFetcher from '../../../components/shared/images/ImageFetcher';

const TeamsUsers = () => {
    const { id } = useParams(); // Récupérer l'id depuis les paramètres de l'URL
    const [users, setUsers] = useState([]);
    const [team, setTeam] = useState(null); // État pour stocker les informations de l'équipe
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        console.log("Team ID sent by TeamsFetchAll to TeamsUsers:", id); // Débogage pour vérifier l'ID reçu

        // Fonction pour récupérer les utilisateurs de l'équipe
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}teams/id/${id}/users`);
                console.log("Data received from /users API:", response.data); // Débogage pour vérifier les données reçues

                let data = response.data;

                // Vérifier si les données reçues sont un tableau ou un objet
                if (Array.isArray(data)) {
                    setUsers(data); // Si c'est un tableau, on le définit directement
                } else if (data && typeof data === 'object') {
                    setUsers([data]); // Si c'est un objet unique, on le transforme en tableau
                } else {
                    setUsers([]); // S'il y a des données inattendues, on définit un tableau vide
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des utilisateurs :", error);
                setError(error);
            }
        };

        // Fonction pour récupérer les informations de l'équipe
        const fetchTeam = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}teams/id/${id}`);
                console.log("Data received from /team API:", response.data); // Débogage pour vérifier les données reçues

                let data = response.data;

                // Vérifier si les données reçues sont un objet valide
                if (data && typeof data === 'object') {
                    setTeam(data);
                } else {
                    setTeam(null); // Si aucune donnée valide n'est reçue, définir team comme null
                }
            } catch (error) {
                console.error("Erreur lors de la récupération de l'équipe :", error);
                setError(error);
            }
        };

        // Appel des deux fonctions
        const fetchData = async () => {
            setLoading(true);
            await Promise.all([fetchUsers(), fetchTeam()]);
            setLoading(false);
        };

        fetchData();
    }, [id]);

    if (loading) return <p>Chargement des données...</p>;
    if (error) return <p>Erreur : {error.message}</p>;

    return (
        <div className="teamsmanagement-container">
            {team && (
                <>
                    <h1 className="teamsmanagement-title">Membres de l'équipe {team.name} <em className="teamstyles-grey">#{team.id_team}</em></h1>
                    <div className="team-logo-team-users">
                        <ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}teams/images`} imageName={team.team_logo} />
                    </div>
                </>
            )}
            {users.length === 0 ? (
                <p>Aucun membre pour cette équipe</p>
            ) : (
                <ul className="teamstyles-listStyle">
                    {users.map((user, index) => (
                        <li key={user.id_user || index}>
                            <strong> {user.firstname.charAt(0).toUpperCase() + user.firstname.slice(1).toLowerCase()} {user.lastname.toUpperCase()}</strong> ({user.username})<br />✉ <a className="teamstyles-email" href={`mailto:${user.email}`}>{user.email}</a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default TeamsUsers;