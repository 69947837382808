import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../../styles/teams/teamQuestsFO.css';
import { jwtDecode } from "jwt-decode";
import ImageFetcher from "../../../components/shared/images/ImageFetcher";

const TeamQuestsFO = () => {
    const [teamQuests, setTeamQuests] = useState([]);
    const [team, setTeam] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        // Fonction pour récupérer les quêtes de l'équipe
        const fetchTeamQuests = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const id_user = jwtDecode(token).user.id_user;

                // Récupération de l'équipe de l'utilisateur
                const teamsResponse = await axios.get(`${process.env.REACT_APP_API_URL}teams/user/${id_user}/team`);
                const userTeam = teamsResponse.data;

                if (!userTeam || !userTeam.id_team) {
                    console.error('Aucune équipe trouvée pour cet utilisateur');
                    setLoading(false);
                    return;
                }

                setTeam(userTeam);

                // Récupération des quêtes de l'équipe
                const teamQuestsResponse = await axios.get(`${process.env.REACT_APP_API_URL}teams/${userTeam.id_team}/quests`);
                setTeamQuests(teamQuestsResponse.data[0].Quests);
            } catch (error) {
                console.error('Erreur lors de la récupération des quêtes de l\'équipe:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTeamQuests();
    }, []);

    const handleNavigateToQuest = (id_quest) => {
        navigate(`/quest/${id_quest}`);
    };

    // Fonction pour déterminer la progression de la quête
    const getQuestProgress = (questState) => {
        switch (questState) {
            case 'En cours':
                return 50;
            case 'Terminé':
                return 100;
            default:
                return 0;
        }
    };

    return (
        <div className="team-quests">
            {/* Équipe */}
            {team && (
                <>
                    <h1 className="team-title">Équipe {team.name} <em className="grey">#{team.id_team}</em></h1>
                    <div className="center">
                        <ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}teams/images`} imageName={team.team_logo} />
                        <p className="team-info">Score : {team.team_score} </p>
                    </div>
                </>
            )}

            {/* Quêtes d'équipe */}
            <h1 className="team-quests-title">Quêtes de l'équipe</h1>
            {loading ? (
                <p>Chargement des quêtes...</p>
            ) : (
                <>
                    {teamQuests.length > 0 ? (
                        <ul className="quest-list">
                            {teamQuests.map((quest) => (
                                <li key={quest.id_quest} className="quest-item"
                                    onClick={() => handleNavigateToQuest(quest.id_quest)}
                                >
                                    <h3><em className="grey">#{quest.id_quest}</em> {quest.quest_title}</h3>
                                    <div className="progress-bar-container">
                                        <div
                                            className="progress-bar"
                                            style={{ width: `${getQuestProgress(quest.Team_Quest.team_quest_state)}%` }}
                                        >
                                            {getQuestProgress(quest.Team_Quest.team_quest_state)} %
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>Aucune quête en cours pour l'équipe.</p>
                    )}
                </>
            )}

        </div>
    );
};

export default TeamQuestsFO;
