import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import '../../../styles/items/itemForm.css';

const ItemForm = ({AddData, data}) => {

    const [itemData, setItemData] = useState({
        item_name:'',
        item_description:'',
        item_category:'Divers',
        item_subcategory:'Aucun',
        item_accessibility:'Libre',
        item_price:'0',
        item_image_name:'',
    });

    const [imageFile, setImageFile] = useState({
        preview:'',
        data:''
    });

    useEffect(() => {
        if (data != null) {
            const setValues = () => {
                setItemData({
                    item_name: data.name,
                    item_description: data.item_description,
                    item_category: data.category,
                    item_subcategory: data.subcategory,
                    item_price: data.price,
                    item_image_name: data.image,
                    item_accessibility: data.accessibility,
                });
            };

                setValues();

            }

        },[data]);

    const handleFileChange = (e) => {
        setImageFile({
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let datas = new FormData();
        if(imageFile.data){
            datas.append('file', imageFile.data);
        } else {
            datas.append('file', '0');
        }
        datas.append('name', itemData.item_name);
        datas.append('description', itemData.item_description);
        datas.append('category', itemData.item_category);
        datas.append('subcategory', itemData.item_subcategory);
        datas.append('accessibility', itemData.item_accessibility);
        datas.append('price', itemData.item_price);
        AddData(datas);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="itemform-container">
                <div className="itemform-element">
                    <label htmlFor='item_name'>Nom</label>
                    <input
                        required
                        type='text'
                        id='item_name'
                        value={itemData.item_name}
                        onChange={(e) => {
                            setItemData({
                                ...itemData,
                                item_name: e.target.value,
                            });
                        }}
                    />
                </div>

                <div className="itemform-element">
                    <label>Description</label>
                    <textarea
                        rows='8'
                        value={itemData.item_description}
                        onChange={(e) => {
                            setItemData({
                                ...itemData,
                                item_description: e.target.value,
                            });
                        }}
                    ></textarea>
                </div>

                <div className="itemform-element">
                    <label>Catégorie</label>
                    <select value={itemData.item_category} onChange={(e) => {
                        setItemData({
                            ...itemData,
                            item_category: e.target.value,
                        });
                    }}>
                        <option defaultValue value='Divers'>Divers</option>
                        <option value='Equipement'>Équipement</option>
                        <option value='Cosmetique'>Cosmetique</option>
                    </select>
                </div>

            {itemData.item_category && itemData.item_category === ('Equipement'||'Cosmetique') ? (
                <>
                <div className="itemform-element">
                    <label>Sous-catégorie (si applicable)</label>
                    <select value={itemData.item_subcategory} onChange={(e) => {
                        setItemData({
                            ...itemData,
                            item_subcategory: e.target.value,
                        })
                    }} >
                        {itemData.item_category === 'Equipement' && (
                            <>
                            <option defaultValue value='Aucun'>Aucun</option>
                            <option value='Tete'>Tête</option>
                            <option value='Torse'>Torse</option>
                            <option value='Jambes'>Jambes</option>
                            <option value='Mains'>Mains</option>
                            <option value='Arme'>Arme</option>
                            </>
                        )}
                    </select>
                </div>

                </>
            ) : ''}

                <div className="itemform-element">
                    <label>Obtention</label>
                    <select value={itemData.item_accessibility} onChange={(e) => {
                        setItemData({
                            ...itemData,
                            item_accessibility: e.target.value,
                        });
                    }}>
                        <option defaultValue value='Libre'>Libre</option>
                        <option value='Quete'>Quête</option>
                        <option value='Objectif'>Objectif</option>
                        <option value='Magasin'>Magasin</option>
                    </select>
                </div>

                {itemData.item_accessibility === 'Magasin' && (
                    <>
                <div className="itemform-element">
                    <label>Prix (si applicable)</label>
                    <input required type='number' value={itemData.item_price} onChange={(e) => {
                        setItemData({
                            ...itemData,
                            item_price: e.target.value,
                        });
                    }}/>
                </div>

                    </>
                )}

                <div className="itemform-element">
                    <label>Image</label>
                    <input type='file' onChange={handleFileChange} />
                    {imageFile.preview && <img src={imageFile.preview} alt={imageFile.data.name} width="50%" />}
                </div>

                <div className="itemform-buttons">
                    <button type='button'>
                        <Link to="/admin/items">Retour</Link>
                    </button>
                    <button type='submit'>
                        {data ? 'Modifier l\'objet' : 'Ajouter l\'objet'}
                    </button>
                </div>
            </div>
        </form>
    )
}

export default ItemForm;