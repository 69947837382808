import React from "react";
import { NavLink } from "react-router-dom";
import '../../../styles/menus/menuDashBoardBtAccueil.css';

/**
 *
 * @returns {Element}
 */
function MenuDashBoardBtAccueil() {


    const handleDisconnection = () => {
        sessionStorage.removeItem('token');
        window.location.reload();
    }

    return (
        <>
            <nav className="menudashboard-navbar">
                <ul className="menudashboard-menu">
                    <li><NavLink to="/admin" className="menudashboard-button">ACCUEIL</NavLink></li>
                    <li><NavLink className="menudashboard-button" onClick={handleDisconnection}>DÉCONNEXION</NavLink></li>
                </ul>
            </nav>
        </>
    );
}

export default MenuDashBoardBtAccueil;