import ItemForm from "../../../components/forms/items/ItemForm";
import PostData from "../../../components/shared/PostData";
import { useNavigate } from "react-router-dom";
import '../../../styles/items/itemCreate.css';

export const ItemCreate = () => {

    const navigate = useNavigate();

    //Creation d'un nouvelle items lors de l'envoi du formulaire.
    const handleData = (itemData) => {
        PostData(`${process.env.REACT_APP_API_URL}items/new`, itemData);
        navigate("/admin/items");
        //Rafraichissement des donnees pour affichage a jour.
        window.location.reload();
    }

    return (
        <div className="itemcreate-container">
            <h1 className="itemcreate-title">
                Gestion des Objets
            </h1>
            <ItemForm AddData={handleData} />
        </div>
    )
}
