import {Route, Router, Routes} from "react-router-dom";

import React from "react";
import {ItemIndex} from "../../pages/BackOffice/items/ItemIndex";
import {ItemCreate} from "../../pages/BackOffice/items/ItemCreate";
import {ItemUpdate} from "../../pages/BackOffice/items/ItemUpdate";


const ItemNavigation = () => {
    return (
        <Routes>
            <Route path="/" element={<ItemIndex/>}/>
            <Route path="/create" element={<ItemCreate/>}/>
            <Route path="/update" element={<ItemUpdate/>}/>
        </Routes>
    )
}

export default ItemNavigation;