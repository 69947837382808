import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import ImageFetcher from "../../../components/shared/images/ImageFetcher";
import '../../../styles/teams/teamsStyles.css';

Modal.setAppElement('#root'); // Configuration de l'élément racine pour le modal

// Composant principal pour afficher toutes les équipes
const TeamsFetchAll = () => {
    // Déclaration des états nécessaires avec useState
    const [teams, setTeams] = useState([]);
    const [filteredTeams, setFilteredTeams] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [quests, setQuests] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Effet pour récupérer toutes les équipes lors du chargement du composant
    useEffect(() => {
        const fetchTeams = async () => {
            try {
                const result = await axios.get(`${process.env.REACT_APP_API_URL}teams/all`); // Requête pour récupérer toutes les équipes
                setTeams(result.data); // Mise à jour de l'état avec les données récupérées
                setFilteredTeams(result.data); // Mise à jour de l'état des équipes filtrées
            } catch (error) {
                console.error('Error fetching teams:', error); // Log de l'erreur
                setError('Une erreur est survenue lors de la récupération des équipes.'); // Mise à jour de l'état d'erreur
            }
        };
        fetchTeams(); // Appel de la fonction pour récupérer les équipes
    }, []);

    // Fonction pour récupérer les détails d'une équipe par ID
    const fetchTeamById = async (id) => {
        try {
            const result = await axios.get(`${process.env.REACT_APP_API_URL}teams/id/${id}`); // Requête pour récupérer les détails de l'équipe
            setSelectedTeam(result.data); // Mise à jour de l'état avec les détails de l'équipe

            const usersResult = await axios.get(`${process.env.REACT_APP_API_URL}teams/id/${id}/users`); // Requête pour récupérer les utilisateurs de l'équipe
            setUsers(usersResult.data); // Mise à jour de l'état avec les utilisateurs

            // Récupération des quêtes de l'équipe
            const questsResult = await axios.get(`${process.env.REACT_APP_API_URL}teams/${id}/quests`); // Requête pour récupérer les quêtes de l'équipe
            setQuests(questsResult.data); // Mise à jour de l'état avec les quêtes
        } catch (error) {
            console.error('Error fetching team, users or quests:', error); // Log de l'erreur
            setError('Une erreur est survenue lors de la récupération des détails de l\'équipe.'); // Mise à jour de l'état d'erreur
        }
    };

    // Fonction pour gérer la recherche d'équipes
    const handleSearch = (e) => {
        setSearchTerm(e.target.value); // Mise à jour de l'état avec le terme de recherche
        setFilteredTeams(teams.filter(team => // Filtrage des équipes en fonction du terme de recherche
            team.name.toLowerCase().includes(e.target.value.toLowerCase())
        ));
    };

    // Fonction pour trier les équipes par nom
    const sortByName = () => {
        const sortedTeams = [...filteredTeams].sort((a, b) => a.name.localeCompare(b.name)); // Tri des équipes par nom
        setFilteredTeams(sortedTeams); // Mise à jour de l'état des équipes filtrées
    };

    // Fonction pour trier les équipes par ID
    const sortById = () => {
        const sortedTeams = [...filteredTeams].sort((a, b) => a.id_team - b.id_team); // Tri des équipes par ID
        setFilteredTeams(sortedTeams); // Mise à jour de l'état des équipes filtrées
    };

    // Fonction pour trier les équipes par date
    // const sortByDate = () => {
    //     const sortedTeams = [...filteredTeams].sort((a, b) => new Date(a.created_at) - new Date(b.created_at)); // Tri des équipes par date
    //     setFilteredTeams(sortedTeams); // Mise à jour de l'état des équipes filtrées
    // };

    // Fonction pour afficher les détails d'une équipe dans le modal
    const handleSee = (id) => {
        fetchTeamById(id); // Appel pour récupérer les détails de l'équipe
        setModalIsOpen(true); // Ouverture du modal
    };

    // Fonction pour supprimer une équipe
    const handleDelete = async (id) => {
        try { // Rajouter message de confirmation
            await axios.delete(`${process.env.REACT_APP_API_URL}teams/delete/${id}`); // Requête pour supprimer l'équipe
            setTeams(teams.filter(team => team.id_team !== id)); // Mise à jour de l'état des équipes
            setFilteredTeams(filteredTeams.filter(team => team.id_team !== id)); // Mise à jour de l'état des équipes filtrées
        } catch (error) {
            console.error('Error deleting team:', error); // Log de l'erreur
            setError('Une erreur est survenue lors de la suppression de l\'équipe.'); // Mise à jour de l'état d'erreur
        }
    };

    // Fonction pour naviguer vers la page d'édition d'une équipe
    const handleEdit = (id) => {
        navigate(`/admin/teams/edit/${id}`); // Navigation vers la page d'édition
    };

    // Fonction pour fermer le modal et réinitialiser les états
    const closeModal = () => {
        setModalIsOpen(false); // Fermeture du modal
        setSelectedTeam(null); // Réinitialisation de l'équipe sélectionnée
        setUsers([]); // Réinitialisation des utilisateurs
        setQuests([]); // Réinitialisation des quêtes
    };

    // Fonction pour naviguer vers la page des utilisateurs d'une équipe
    const handleSeeUsers = (id) => {
        navigate(`/admin/teams/${id}/users`);
    };

    // Fonction pour naviguer vers la page des quêtes d'une équipe
    const handleSeeQuests = (id) => {
        navigate(`/admin/teams/${id}/quests`);
    };

    return (
        <div className="teamsmanagement-container">
            <h1 className="teamsmanagement-title">Liste des équipes</h1>
            <div className="teamsform-container noflex">
                {error && <p className="error-message">{error}</p>}
                <div className="teamstyles-search-and-sort-container">
                    <input
                        type="text"
                        placeholder="Rechercher une équipe en saisissant son nom"
                        value={searchTerm}
                        onChange={handleSearch}
                        className="search-bar"
                    />
                    <div className="teamstyles-sort-buttons">
                        <button aria-label="Trier par nom" onClick={sortByName}>⇵ nom</button>
                        {/* <button aria-label="Trier par date" onClick={sortByDate}>⇵ date</button> */}
                        <button aria-label="Trier par ID" onClick={sortById}>⇵ #</button>
                    </div>
                </div>
            </div>
            <div className="teamsmanagement-container">
                {filteredTeams.length === 0 ? (
                    <div className="teamstyles-sort-buttons"><p>Aucune équipe trouvée. <button onClick={() => { navigate('/admin/teams/create') }}>+ Ajouter une équipe</button></p></div>
                ) : (
                    filteredTeams.map((team) => (
                        <div key={team.id_team} className="teamsmanagement-team-card">
                            <div className="teamsmanagement-team-card-content">
                                <ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}teams/images`} imageName={team.team_logo} />
                                <h2>{team.name}</h2><em className="grey">#{team.id_team}</em>
                                <p className="teamsmanagement-team-score">Score : <strong>{team.team_score}</strong></p>
                                <p className="teamsmanagement-team-buttons">
                                    <button aria-label="Supprimer" className="buttonDelete tooltip" onClick={() => handleDelete(team.id_team)} data-tooltip="Supprimer l'équipe">🗑</button>
                                    <button aria-label="Modifier" className="buttonEdit tooltip" onClick={() => handleEdit(team.id_team)} data-tooltip="Modifier l'équipe">🖉</button>
                                    <button aria-label="Voir" className="buttonSee tooltip" onClick={() => handleSee(team.id_team)} data-tooltip="Voir les détails de l'équipe">👁</button>
                                </p>
                            </div>
                        </div>
                    ))
                )}
            </div>
            {selectedTeam && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Détails de l'équipe"
                    className="modal"
                    overlayClassName="overlay"
                >
                    <h2 className="teamsmanagement-modal-title">Détails de l'équipe <em className="teamstyles-grey">#{selectedTeam?.id_team}</em></h2>
                    <ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}teams/images`} imageName={selectedTeam.team_logo} />
                    <p className="teamsmanagement-modal-name">{selectedTeam.name}</p>
                    <p>Score : <strong>{selectedTeam.team_score}</strong></p>
                    <p>Membres : <strong>{users.length}</strong></p>
                    <p>Quêtes d'équipe : <strong>{quests.length}</strong></p>
                    <p className="center buttons">
                        <button aria-label="Aller vers la page détaillée avec la liste des quêtes de l'équipe" className="buttonSee tooltip" onClick={() => handleSeeQuests(selectedTeam?.id_team)} data-tooltip="Voir toutes les quêtes associées">ℚ</button>
                        <button aria-label="Aller vers la page détaillée avec la liste des membres de l'équipe" className="buttonSee tooltip" onClick={() => handleSeeUsers(selectedTeam?.id_team)} data-tooltip="Voir tous les membres associés">👤</button>
                        <button aria-label="Fermer la fenêtre détails" className="buttonClose tooltip" onClick={closeModal} data-tooltip="Fermer la fenêtre">🗙</button>
                    </p>
                </Modal>
            )}
        </div>
    );
};

export default TeamsFetchAll;