import {NavLink} from "react-router-dom";
import React from "react";
import '../../../styles/menus/menuDashBoardBtSections.css';

/**
 *
 * @returns {Element}
 */
const MenuDashBoardBtSections = () => {
    return (
        <div className="menudashboard-sections-container">
            <NavLink className="menudashboard-sections-button" to="/admin/users">SECTION USERS</NavLink>
            <NavLink className="menudashboard-sections-button" to="/admin/objectives">SECTION OBJECTIVES</NavLink>
            <NavLink className="menudashboard-sections-button" to="/admin/quests">SECTION QUESTS</NavLink>
            <NavLink className="menudashboard-sections-button" to="/admin/teams">SECTION TEAMS</NavLink>
            <NavLink className="menudashboard-sections-button" to="/admin/items">SECTION ITEMS</NavLink>
        </div>
    )
}

export default MenuDashBoardBtSections;