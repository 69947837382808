import axios from "axios";
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { truncateText } from '../../../components/shared/TruncateText';
import '../../../styles/objectives/userLinkToObjective.css';


const UserLinkToObjective = () => {
    const { id_user } = useParams();
    const [user, setUser] = useState({});
    const [objectives, setObjectives] = useState([]);
    const [selectedObjectives, setSelectedObjectives] = useState([]);
    const [expandedObjectiveId, setExpandedObjectiveId] = useState(null);
    const [objectiveDetails, setObjectiveDetails] = useState({}); 
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('lastname_ASC');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalObjectives, setTotalObjectives] = useState(0);
    const limit = 10;
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}objectives/users/${id_user}/userObjectives`);
                setUser(userResponse.data);

                // Récupérer tous les objectifs
                const objectivesResponse = await axios.get(`${process.env.REACT_APP_API_URL}objectives/all?page=${currentPage}&limit=${limit}&searchTerm=${searchTerm}&sortBy=${sortBy}`);
                setObjectives(objectivesResponse.data.data);
                setTotalPages(objectivesResponse.data.totalPages);
                setTotalObjectives(objectivesResponse.data.totalObjectives);

                // Récupérer les objectifs assignés à l'utilisateur
                const assignedObjectives = userResponse.data.Objectives.map(obj => obj.id_objective);
                setSelectedObjectives(assignedObjectives);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [id_user, currentPage, searchTerm, sortBy]);


    // Fonction pour déplier ou réduire le texte
    const toggleExpand = (objectiveId) => {
        if (expandedObjectiveId === objectiveId) {
            setExpandedObjectiveId(null); // Réduire le texte si c'est déjà déplié
        } else {
            setExpandedObjectiveId(objectiveId); // Déplier le texte pour l'objectif en cours
        }
    };

    const handleObjectiveChange = (objectiveId) => {
        setSelectedObjectives(prevSelected => {
            const isAlreadySelected = prevSelected.includes(objectiveId);
            const newSelectedObjectives = isAlreadySelected
                ? prevSelected.filter(id => id !== objectiveId)
                : [...prevSelected, objectiveId];

            return newSelectedObjectives;
        });
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault(); // Empêche le rechargement par défaut de la page
        try {
            const objectivesToSave = selectedObjectives.map((objectiveId) => ({
                id_objective: objectiveId,
                recurrence: objectiveDetails[objectiveId]?.recurrence || 'Aucune',
                reminder: objectiveDetails[objectiveId]?.reminder || null,
                objective_state: objectiveDetails[objectiveId]?.objectiveState || 'En cours'
            }));

            await axios.post(`${process.env.REACT_APP_API_URL}objectives/users/${id_user}/objectives`, {
                objectives: objectivesToSave
            });
            navigate('/admin/objectives/sortUserObjective');   //  Redirection vers la liste des objectifs triés par utilisateurs
        } catch (error) {
            setError(error.message);
            alert('Error updating Objectives');
        }
    };

    const handleBackToListClick = () => {
        navigate('/admin/objectives/');
    };

    const handleBackToUserObjectiveList = () => {
        navigate('/admin/objectives/sortUserObjective');
    };

    const handleRecurrenceChange = (objectiveId, value) => {
        setObjectiveDetails(prevDetails => ({
            ...prevDetails,
            [objectiveId]: {
                ...prevDetails[objectiveId],
                recurrence: value
            }
        }));
    };

    const handleReminderChange = (objectiveId, value) => {
        setObjectiveDetails(prevDetails => ({
            ...prevDetails,
            [objectiveId]: {
                ...prevDetails[objectiveId],
                reminder: value
            }
        }));
    };

    const handleObjectiveStateChange = (objectiveId, value) => {
        setObjectiveDetails(prevDetails => ({
            ...prevDetails,
            [objectiveId]: {
                ...prevDetails[objectiveId],
                objectiveState: value
            }
        }));
    };

    const handleResetClick = () => {
        setSearchTerm('');
        setCurrentPage(1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };


    if (loading) {
        return <p>Chargement...</p>;
    }

    if (error) {
        return <p>Erreur : {error}</p>;
    }

    return (
        <div className="objectivesSortByUsers-container">
            <h1 className="objectivesgetall-title">Assigner des objectifs à l'utilisateur : {user.firstname} {user.lastname.toUpperCase()}</h1>
            <h2 className="totalObjectives">Nombre d'objectifs disponibles : <span className="nbTotal">{totalObjectives}</span></h2>
            <p id="ObjectiveExplain">Cliquez sur le titre de l'objectif pour voir les détails</p>

            <div>
                <label className="objectivecreateform-label">Trier par </label>
                <select onChange={(e) => setSortBy(e.target.value)} className="sortSelect">
                    <option value="title">Titre</option>
                    <option value="category">Catégorie</option>
                    <option value="description">Description</option>
                    <option value="difficulty">Difficulté</option>
                    <option value="gold_reward_ASC">Gain d'Or croissant</option>
                    <option value="gold_reward_DESC">Gain d'Or décroissant</option>
                    <option value="id_objective_ASC">ID par ordre croissant</option>
                    <option value="id_objective_DESC">ID par ordre décroissant</option>
                </select>
            </div>

            <div className="objectiveSearchLabel">
                <label>Contenant </label>
                <input
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setCurrentPage(1);
                    }}
                    value={searchTerm}
                />
                <button onClick={handleResetClick}>Réinitialiser la recherche</button>
            </div>

            <button className="objectivesSave-btn" onClick={handleSaveChanges}>Sauvegarder les Changements</button>

            <div className="userLinkNav">
                <button className="objectivesNav1-btn" onClick={handleBackToListClick}>Retour à la liste des objectifs</button>
                <button className="objectivesNav2-btn" onClick={handleBackToUserObjectiveList}>Retour à la liste des objectifs classés par Utilisateurs</button>
            </div>

            <table className="objectivesgetall-table">
                <thead>
                    <tr className="objectivesgetall-header">
                        <th>Ajouter</th>
                        <th>Id</th>
                        <th>Titre</th>
                        <th>Catégorie</th>
                        <th>Description</th>
                        <th>Difficulté</th>
                        <th>Gain d'Or</th>
                        <th>Gain d'Item</th>
                        <th>Récurrence, Rappel et Statut</th>
                    </tr>
                </thead>

                <tbody className="objectivesgetall-body">
                    {objectives.map(objective => (
                        <tr className="objectivesgetall-row" key={objective.id_objective}>
                            <td className="objectivesgetall-desc-1">
                                <input
                                    type="checkbox"
                                    checked={selectedObjectives.includes(objective.id_objective)}
                                    onChange={() => handleObjectiveChange(objective.id_objective)}
                                />
                            </td>
                            <td className="objectivesgetall-desc">{objective.id_objective}</td>
                            <td className="objectivesgetall-desc-title">
                                <Link to={`/admin/objectives/${objective.id_objective}/details`} className="objectivesgetall-link">
                                    {objective.objective_title}
                                </Link>
                            </td>
                            <td className="objectivesgetall-desc-2">{objective.objective_category}</td>
                            <td className="objectivesgetall-desc-2">
                                {expandedObjectiveId === objective.id_objective
                                    ? objective.objective_description
                                    : truncateText(objective.objective_description, 30)}
                                {objective.objective_description.length > 30 && (
                                    <button className="toggleExpand" onClick={() => toggleExpand(objective.id_objective)}>
                                        {expandedObjectiveId === objective.id_objective ? "Voir moins" : "Voir plus"}
                                    </button>
                                )}
                            </td>
                            <td className="objectivesgetall-desc-1">{objective.objective_difficulty_level}</td>
                            <td className="objectivesgetall-desc-1">{objective.objective_gold_reward}</td>
                            <td className="objectivesgetall-desc-3">
                                {objective.Items && objective.Items.length > 0 ? (
                                    <div className="ObjectiveItemsContainer">
                                        {objective.Items.map((item, index) => (
                                            <ul key={index} className="itemDetails">
                                                <li>{item.name}</li>
                                            </ul>
                                        ))}
                                    </div>
                                ) : (
                                    <p className="noAnswer">Aucun item associé</p>
                                )}
                            </td>
                            <td className="objectivesgetall-desc-3">
                                <div className="ObjectiveItemsContainer">
                                    <div className="userObjectiveSelect">
                                        <select
                                            value={objectiveDetails[objective.id_objective]?.recurrence || ''}
                                            onChange={e => handleRecurrenceChange(objective.id_objective, e.target.value)}
                                            className="sortSelect"
                                        >
                                            <option value="Aucune">Aucune</option>
                                            <option value="Quotidienne">Quotidienne</option>
                                            <option value="Hebdomadaire">Hebdomadaire</option>
                                            <option value="Mensuelle">Mensuelle</option>
                                            <option value="Annuelle">Annuelle</option>
                                        </select>
                                    </div>
                                    <div className="userObjectiveSelect">
                                        <label>Date de rappel :</label>
                                        <input type="date"
                                            value={objectiveDetails[objective.id_objective]?.reminder || ''}
                                            onChange={e => handleReminderChange(objective.id_objective, e.target.value)}
                                            className="sortSelect"
                                        />                                    </div>
                                    <div className="userObjectiveSelect">
                                        <select
                                            value={objectiveDetails[objective.id_objective]?.objectiveState || ''}
                                            onChange={(e) => handleObjectiveStateChange(objective.id_objective, e.target.value)}
                                            className="sortSelect"
                                        >
                                            <option value="En cours">En cours</option>
                                            <option value="Terminé">Terminé</option>
                                        </select>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="objectivesPagination">
                {currentPage > 1 && (
                    <button onClick={handlePreviousPage}>Précédent</button>
                )}
                <span>Page <span id="currentPage">{currentPage}</span> sur {totalPages}</span>
                {currentPage < totalPages && (
                    <button onClick={handleNextPage}>Suivant</button>
                )}
            </div>
        </div>
    );
};

export default UserLinkToObjective;