import React, {useEffect, useState} from 'react';
import axios from 'axios';
import '../../../styles/quests/questList.css';

const QuestList = () => {
    const [quests, setQuests] = useState([]);

    useEffect(() => {
        const fetchQuests = async () => {
            try {
                const result = await axios.get(`${process.env.REACT_APP_API_URL}quests`);
                setQuests(result.data);
            } catch (error) {
                console.error('Error fetching quests:', error);
            }
        };
        fetchQuests();
    }, []);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}quests/${id}`);
            setQuests(quests.filter((quest) => quest.id_quest !== id));
        } catch (error) {
            console.error('Error deleting quests:', error);
        }
    };

    return (
        <div className="questlist-container">
            <h2 className="questlist-header">All Quests</h2>
            <ul className="questlist">
                {quests.map((quest) => (
                    <li key={quest.id_quest} className="questlist-item">
                        <div className="questlist-item-header">
                            <h3>{quest.id_quest} - {quest.quest_title}</h3>
                        </div>
                        <p className="questlist-item-description">{quest.quest_description}</p>
                        <button className="questlist-button" onClick={() => handleDelete(quest.id_quest)}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default QuestList;