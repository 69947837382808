import {useEffect, useRef, useState} from "react";


/**
 *
 * @param message string - message a afficher dans l'element
 * @param onClose function - callback lors de la disparission du message (relier a un etat)
 * @returns {JSX.Element}
 * @constructor
 */
const MessageElement = ({message, onClose}) => {
    const messageRef = useRef(null);

    useEffect(()=>{
            let opacity = 1;
            const fade = () => {
                if(opacity <=0) {
                    onClose();
                } else {
                    opacity -= 0.05;
                    if(messageRef.current) {
                        messageRef.current.style.opacity = opacity;
                    }
                    requestAnimationFrame(fade);
                }
            };

        const timer = setTimeout(fade, 2000);

        return () => clearTimeout(timer);
    },[onClose]);



    return (
            <div ref={messageRef} style={styles.message}>
                {message}
            </div>
    )

}

const styles = {
    message:{
        padding: '10px 20px',
        backgroundColor: '#EDE1CF',
        color: 'black',
        borderRadius: '5px',
        opacity: 1, // Opacité initiale
        transition: 'opacity 0.5s ease-out'
    }
};

export default MessageElement;