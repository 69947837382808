import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../../styles/users/UserQuestForm.css';

const UserQuestForm = () => {
    const [users, setUsers] = useState([]);
    const [quests, setQuests] = useState([]);
    const [questStates] = useState(['En cours', 'Terminé']); // État des quêtes
    const [selectedUserId, setSelectedUserId] = useState('');
    const [selectedQuestId, setSelectedQuestId] = useState('');
    const [selectedQuestState, setSelectedQuestState] = useState(''); // État sélectionné

    useEffect(() => {
        fetchUsers();
        fetchQuests();
    }, []);

    const fetchUsers = async () => {
        try {
            const result = await axios.get(`${process.env.REACT_APP_API_URL}users/all`);
            setUsers(result.data);
            console.log('Users fetched successfully:', result.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchQuests = async () => {
        try {
            const result = await axios.get(`${process.env.REACT_APP_API_URL}quests`);
            setQuests(result.data);
            console.log('Quests fetched successfully:', result.data);
        } catch (error) {
            console.error('Error fetching quests:', error);
        }
    };

    const handleSubmitUserQuest = async (e) => {
        e.preventDefault();

        // Assurez-vous que les ID utilisateur et quête sont valides
        if (!selectedUserId || !selectedQuestId || !selectedQuestState) {
            console.error('Please select a user, a quest, and a quest state.');
            return;
        }

        const userQuestData = {
            id_user: parseInt(selectedUserId),
            id_quest: parseInt(selectedQuestId),
            quest_state: selectedQuestState
        };

        try {
            const result = await axios.post(`${process.env.REACT_APP_API_URL}quests/user-quests`, userQuestData);
            console.log('UserQuest created successfully:', result.data);
            // Réinitialiser les sélections après la soumission
            setSelectedUserId('');
            setSelectedQuestId('');
            setSelectedQuestState(''); // Réinitialisé ici
        } catch (error) {
            console.error('Error creating UserQuest:', error);
        }
    };

    return (
        <form onSubmit={handleSubmitUserQuest}>
            <div>
                <label htmlFor="user-select">Select User:</label>
                <select
                    id="user-select"
                    value={selectedUserId}
                    onChange={(e) => setSelectedUserId(e.target.value)}
                >
                    <option value="">--Choose a user--</option>
                    {users.map((user) => (
                        <option key={user.id_user} value={user.id_user}>
                            {user.username}
                        </option>
                    ))}
                </select>
            </div>

            <div>
                <label htmlFor="quest-select">Select Quest:</label>
                <select
                    id="quest-select"
                    value={selectedQuestId}
                    onChange={(e) => setSelectedQuestId(e.target.value)}
                >
                    <option value="">--Choose a quest--</option>
                    {quests.map((quest) => (
                        <option key={quest.id_quest} value={quest.id_quest}>
                            {quest.quest_title}
                        </option>
                    ))}
                </select>
            </div>

            <div>
                <label htmlFor="quest-state-select">Select Quest State:</label>
                <select
                    id="quest-state-select"
                    value={selectedQuestState}
                    onChange={(e) => setSelectedQuestState(e.target.value)}
                >
                    <option value="">--Choose quest state--</option>
                    {questStates.map((state, index) => (
                        <option key={index} value={state}>
                            {state}
                        </option>
                    ))}
                </select>
            </div>

            <button type="submit">Assign Quest to User</button>
        </form>
    );
};

export default UserQuestForm;