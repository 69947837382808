// DefiPerso-Frontend\src\pages\BackOffice\objectives\ObjectiveGetOne.js
import axios from "axios";
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ImageFetcher from '../../../components/shared/images/ImageFetcher';
import '../../../styles/objectives/objectiveGetOne.css';

const ObjectiveGetOne = () => {
    const [objective, setObjective] = useState([]);
    const { id } = useParams();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


    // Chargement des objectifs depuis l'API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}objectives/${id}`);
                setObjective(response.data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [id]);

    // Fonction pour naviguer vers la liste de tous les objectifs
    const handleBackToListClick = () => {
        navigate('/admin/objectives/');
    };


    // Fonction pour supprimer un objectif
    const handleDelete = async (id_objective) => {
        if (window.confirm('Etes-vous sûr(e) de vouloir supprimer cet objectif ?')) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}objectives/${id_objective}`, { method: 'DELETE' })
                navigate('/admin/objectives/');
            } catch (error) {
                console.error('Error deleting objective:', error);
            }
        }
    };

    return (
        <div className="objectivegetone-container">
            <h1 className="objectivegetone-title">Détails de l'objectif</h1>

            {loading ? (
                <p>Chargement...</p>
            ) : error ? (
                <p>Erreur: {error}</p>
            ) : (
                <div className="objectivegetone-card">
                    <p className="objectivegetone-title">{objective.objective_title}</p>

                    <div className="objectivegetone-card-text">
                        <div>
                            <p className="objectivegetone-details">Id n° {objective.id_objective}</p>
                            <p className="objectivegetone-details">Catégorie : {objective.objective_category}</p>
                            <p className="objectivegetone-details">Description : {objective.objective_description}</p>
                            <p className="objectivegetone-details">Difficulté : {objective.objective_difficulty_level}</p>
                            <p className="objectivegetone-details">Gain en Or : {objective.objective_gold_reward}</p>
                        </div>


                        <div>
                            {/* Boucle pour afficher les items associés */}
                            <p className="objectivegetone-details-section">Gains d'Item(s) :</p>
                            {objective.Items && objective.Items.length > 0 ? (
                                objective.Items.map((item, index) => (
                                    <ul key={index} className="itemDetails">
                                        <li className="objectivegetone-details"> <p>{item.name}</p>
                                            <ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}items/images`} imageName={item.image} />
                                        </li>
                                    </ul>
                                ))
                            ) : (
                                <p className="objectivegetone-details">Aucun item associé.</p>
                            )}

                            <div>
                                {/* Boucle pour afficher les users associés */}
                                <p className="objectivegetone-details-section">Utilisateur(s) associé(s) :</p>
                                {objective.Users && objective.Users.length > 0 ? (
                                    objective.Users.map((user, index) => (
                                        <ul key={index} className="itemDetails">
                                            <li className="objectivegetone-details-items">
                                                <p className="objective-user">{user.firstname} {user.lastname.toUpperCase()}</p>
                                                <p>Pseudo : {user.username}</p>
                                                <p>Type de compte : {user.account_type}</p>
                                                <p>Email du tuteur : {user.tutor_email}</p>
                                            </li>
                                        </ul>
                                    ))
                                ) : (
                                    <p className="objectivegetone-details">Aucun utilisateur associé.</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="objectivegetone-actions">
                        <button className="objectivegetone-btn-action" onClick={() => navigate(`/admin/objectives/${objective.id_objective}/edit`)}>Modifier</button>
                        <button className="objectivegetone-btn-action" onClick={handleBackToListClick}>Retour à la liste des objectifs</button>
                        <button className="objectivegetone-btn-action" onClick={() => handleDelete(objective.id_objective)}>Supprimer</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ObjectiveGetOne;