import React from "react";
import '../../../styles/components/paginationButtons.css'

/**
 *
 * @param nextPage callback - action lors du clic sur le boutton suivant
 * @param previousPage callback - action lors du clic sur le boutton precedent
 * @param currentPage int - affichage de la page actuelle dans l'element
 * @param totalPages int - affichage du nombre de page total dans l'element
 * @returns {Element}
 */
const PaginateElement = ({nextPage,previousPage , currentPage, totalPages}) => {

    return (
        <div className="Pagination">
            <button onClick={previousPage}>Précédent</button>
            <span>Page <span id="currentPage">{currentPage}</span> sur {totalPages}</span>
            <button onClick={nextPage}>Suivant</button>
        </div>
    )
}
export default PaginateElement;