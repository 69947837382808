// DefiPerso-Frontend\src\pages\BackOffice\objectives\ObjectivesSortByUsers.js

import axios from "axios";
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../../../styles/objectives/objectivesSortByUsers.css';


const ObjectivesSortByUsers = () => {
    const [userObjectives, setUserObjectives] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('lastname_ASC'); // Tri par défaut réglé sur le nom
    const [sortUsersBy, setSortUsersBy] = useState('AllUsers');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalUsersWithObjectives, setTotalUsersWithObjectives] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0)
    const limit = 5;
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}objectives/userObjectives/all?page=${currentPage}&limit=${limit}&searchTerm=${searchTerm}&sortUsersBy=${sortUsersBy}&sortBy=${sortBy}`);
                setUserObjectives(response.data.data);
                setTotalPages(response.data.totalPages);
                setTotalUsersWithObjectives(response.data.totalUsersWithObjectives);
                setTotalUsers(response.data.totalUsers);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [currentPage, searchTerm, sortUsersBy, sortBy]);

    const handleObjectivesGetAll = () => {
        navigate('/admin/objectives/');
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleResetClick = () => {
        setSearchTerm('');
        setCurrentPage(1);
    };

    const handleLinkUserObjective = (id_user) => {
        navigate(`/admin/objectives/${id_user}/LinkUserObjective`);
    };


    if (loading) return <p>Chargement...</p>;
    if (error) return <p>Erreur: {error}</p>;

    return (
        <div className="objectivesSortByUsers-container">
            <h1 className="objectivesgetall-title">Liste des Objectifs classés par Utilisateurs</h1>

            <h2 className="totalUserObjectives"> Nombre d'utilisateurs ayant un ou plusieurs objectifs : <span className="nbTotal">{totalUsersWithObjectives}</span></h2>
            <h2 className="totalUserObjectives"> Nombre total d'utilisateurs : <span className="nbTotal">{totalUsers}</span></h2>


            <p id="ObjectiveExplain">Cliquez sur le nom pour voir les détails</p>

            {/* Sélection de l'affichage des utilisateurs */}
            <div className="objectivecreateform-label">
                <label>Afficher </label>
                <select onChange={(e) => setSortUsersBy(e.target.value)} value={sortUsersBy} className="sortSelect">
                    <option value="AllUsers">Tous les utilisateurs</option>
                    <option value="UsersWithObjective">Seulement les utilisateurs qui ont un ou plusieurs objectifs</option>
                    <option value="UsersWithoutObjective">Seulement les utilisateurs qui n'ont aucun objectif</option>
                </select>
            </div>

            {/* Tri */}
            <div className="objectivecreateform-label">
                <label>Trier par </label>
                <select onChange={(e) => setSortBy(e.target.value)} className="sortSelect">
                    <option value="lastname_ASC">Nom par ordre croissant</option>
                    <option value="lastname_DESC">Nom par ordre décroissant</option>
                    <option value="tutor_email">Email du tuteur</option>
                    <option value="account_type">Type de compte</option>
                    <option value="user_score_ASC">Score de l'utilisateur par ordre croissant</option>
                    <option value="user_score_DESC">Score de l'utilisateur par ordre décroissant</option>
                </select>
            </div>

            {/* Recherche */}
            <div className="objectivecreateform-label">
                <label>Contenant </label>
                <input
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setCurrentPage(1);
                    }} value={searchTerm} />

                <button onClick={handleResetClick}>Réinitialiser la recherche</button>
            </div>

            <div className="sort-objectives-with-users">
                <button className="objectivesSortByUsers-btn" onClick={handleObjectivesGetAll}>
                    Retourner à la liste complète des objectifs
                </button>
            </div>

            <table className="objectivesgetall-table">
                <thead>
                    <tr className="objectivesgetall-header">
                        <th>Nom et pseudo</th>
                        <th>Score</th>
                        <th>Type de compte</th>
                        <th>Objectif(s)</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody className="objectivesByUsers-body">
                    {userObjectives.map((userObjective) => (
                        <tr className="objectivesByUsers-row" key={userObjective.id_user}>
                            <td className="objectivesgetall-desc">
                                <Link
                                    to={`/admin/objectives/${userObjective.id_user}/detailsUserObjective`}
                                    className="objectivesgetall-link">
                                    {userObjective.lastname.toUpperCase()} {userObjective.firstname}
                                </Link>
                                <p><span className="bold">{userObjective.username}</span></p>
                                id : {userObjective.id_user}
                            </td>
                            <td className="objectivesgetall-desc">{userObjective.user_score}</td>
                            <td className="objectivesgetall-desc">
                                <p>{userObjective.account_type}</p>
                                {userObjective.tutor_email || 'Aucun email'}
                            </td>
                            <td className="objectivesgetall-desc-2">
                                {userObjective.Objectives && userObjective.Objectives.length > 0 ? (
                                    <ul>
                                        {userObjective.Objectives.map(objective => (
                                            <li key={objective.id_objective}>
                                                <p className="obj-bold">{objective.objective_title}</p>
                                                <p><span className="underline">Catégorie</span> : {objective.objective_category} </p>
                                                <p><span className="underline">Difficulté</span> : {objective.objective_difficulty_level}</p>
                                                <p><span className="underline">Récurrence</span> : {objective.UserObjective.recurrence}</p>
                                                <p><span className="underline">Statut</span> : {objective.UserObjective.objective_state}</p>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>Aucun objectif assigné</p>
                                )}
                            </td>
                            <td className="objectivesgetall-desc">
                                <button className="objectives-Users-LinkBtn"
                                    onClick={() => handleLinkUserObjective(userObjective.id_user)}>
                                    Assigner un objectif</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination */}
            <div className="objectivesPagination">
                {currentPage > 1 && (
                    <button onClick={handlePreviousPage}>Précédent</button>
                )}
                <span>Page <span id="currentPage">{currentPage}</span> sur {totalPages}</span>

                {currentPage < totalPages && (
                    <button onClick={handleNextPage}>Suivant</button>
                )}
            </div>
        </div>
    );
};

export default ObjectivesSortByUsers;