import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import '../../../styles/users/UserInventory.css';
import InventoryGrid from "../../../components/shared/InventoryGrid";
import useUserInventory from "../../../components/shared/useUserInventory";

/**
 * UserInventory - Composant pour gérer et afficher l'inventaire d'un utilisateur spécifique.
 *
 * @returns {JSX.Element} Le composant UserInventory.
 */
const UserInventory = () => {
    // Récupère l'ID de l'URL
    const {id} = useParams();
    const {userInventory, selectedItemDetails, loading, error, handleDetails, handleDeleteItem} =
        useUserInventory(id);
    // Hook useNavigate pour les redirections de route
    const navigate = useNavigate();


    return (
        <div className="inventory-page">
            <div className="inventory-header">
                <h2>Inventaire de l'utilisateur {id}</h2>
                <button className='button-inventory button-add-items'
                        onClick={() => navigate(`/admin/users/${id}/inventory/new`)}>
                    +
                </button>
                <button className='button-inventory button-back'
                        onClick={() => navigate(`/admin/users/${id}`)}>
                    ↩
                </button>
                <p>Nombre d'objets dans l'inventaire : {userInventory.length}</p>
            </div>
            {error && <p className="error-message">{error}</p>}
            {loading ? (
                <p>Loading...</p>
            ) : (
                <InventoryGrid
                    userInventory={userInventory}
                    selectedItemDetails={selectedItemDetails}
                    handleDetails={handleDetails}
                    handleDeleteItem={handleDeleteItem}
                />
            )}
        </div>
    )
}

export default UserInventory;
