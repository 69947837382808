import React, {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import ImageFetcher from "../../../components/shared/images/ImageFetcher";

const UserInventoryAddItem = () => {
    // Recupère l'ID par l'URL
    const {id} = useParams();
    // items
    const [items, setItems] = useState([]);
    const [filteredItemsWithCategory, setFilteredItemsWithCategory] = useState([]);
    const [filteredItemsWithSubcategory, setFilteredItemsWithSubcategory] = useState([]);
    const [itemId, setItemId] = useState('');
    const [itemsCategorySelected, setItemsCategorySelected] = useState('Tous');
    const [itemsSubcategorySelected, setItemsSubcategorySelected] = useState('Tous');
    const itemsCategory = ['Tous', 'Divers', 'Equipement', 'Cosmetique', 'Compagnon'];
    const itemsSubcategory = ['Tous', 'Aucun', 'Tete', 'Torse', 'Jambes', 'Mains', 'Arme'];
    // hook navigate
    const navigate = useNavigate();
    // const item sélectionner
    const selectedItem = items.find(item => parseInt(item.id_item) === parseInt(itemId));

    // Récupération des items à partir de l'API lors du montage du composant
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}items/all`);
                setItems(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [id]);

    // Filtre les items par catégorie sélectionnée lors du chargement des items
    useEffect(() => {
        handleSelectCategory({target: {value: itemsCategorySelected}});
    }, [items]);
    // Filtre les items par sous-catégorie sélectionnée lorsque les items filtrés par catégorie sont mis à jour
    useEffect(() => {
        handleSelectSubcategory({target: {value: itemsSubcategorySelected}});
    }, [filteredItemsWithCategory]);

    // Met à jour l'ID de l'item sélectionné
    const handleSelectChange = (e) => {
        setItemId(e.target.value);
    };

    // Filtre les items en fonction de la catégorie sélectionnée
    const handleSelectCategory = (e) => {
        const category = e.target.value;
        setItemsCategorySelected(category);
        if (category === 'Tous') {
            setFilteredItemsWithCategory(items);
        } else {
            setFilteredItemsWithCategory(items.filter(item => item.category === category));
        }
        // Réinitialise la sous-catégorie et les items filtrés par sous-catégorie lorsque la catégorie change
        setItemsSubcategorySelected('Tous');
        setFilteredItemsWithSubcategory([]);
    };
    // Filtre les items en fonction de la sous-catégorie sélectionnée
    const handleSelectSubcategory = (e) => {
        const subcategory = e.target.value;
        setItemsSubcategorySelected(subcategory);
        if (subcategory === 'Tous') {
            setFilteredItemsWithSubcategory(filteredItemsWithCategory);
        } else {
            setFilteredItemsWithSubcategory(filteredItemsWithCategory.filter(item => item.subcategory === subcategory));
        }
        // Réinitialise l'ID de l'item sélectionné lorsque la sous-catégorie change
        setItemId('');
    };

    // Ajoute un item à l'inventaire de l'utilisateur
    const handleItem = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}users/${id}/inventory/${itemId}/new`, {
                id: id,
                itemId: itemId,
            });
            alert('Item ajouté à l\'inventaire');
            navigate(`/admin/users/${id}/inventory`);
        } catch (error) {
            console.error('Error adding items:', error);
            alert('Erreur lors de l\'ajout de l\'items');
            navigate(`/admin/users/${id}/inventory`);
        }
    };

    return (
        <div>
            <button onClick={() => {
                navigate(`/admin/users/${id}/inventory`);
            }}>Retour inventaire user
            </button>
            <h1>Utilisateur {id}</h1>
            <h2>Ajouter un item à l'inventaire</h2>
            <p>
                Sélectionner la catégorie :
                <select value={itemsCategorySelected} onChange={handleSelectCategory}>
                    {itemsCategory.map((category) => (
                        <option key={category} value={category}>{category}</option>
                    ))}
                </select>
            </p>
            <p>
                Sélectionner la sous-catégorie :
                <select value={itemsSubcategorySelected} onChange={handleSelectSubcategory}>
                    {itemsSubcategory.map((subcategory) => (
                        <option key={subcategory} value={subcategory}>{subcategory}</option>
                    ))}
                </select>
            </p>

            <form onSubmit={handleItem}>
                <p>
                    Sélectionner un item :
                    <select value={itemId} onChange={handleSelectChange}>
                        <option value="">Choisissez un item</option>
                        {filteredItemsWithSubcategory.map((item) => (
                            <option key={item.id_item} value={item.id_item}>{item.id_item}--{item.name}</option>
                        ))}
                    </select>
                </p>
                <button type="submit">Ajouter</button>
            </form>

            {itemId && selectedItem && (
                <div>
                    <h3>{selectedItem.name}</h3>
                    <div>
                        <ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}items/images`}
                                      imageName={selectedItem.image}/>
                    </div>
                    <p><strong>ID:</strong> {selectedItem.id_item}</p>
                    <p><strong>Description:</strong> {selectedItem.item_description}</p>
                    <p><strong>Price:</strong> {selectedItem.price} p.o</p>
                    <p><strong>Category:</strong> {selectedItem.category}</p>
                    <p><strong>Subcategory:</strong> {selectedItem.subcategory}</p>
                    <p><strong>Type:</strong> {selectedItem.accessibility}</p>
                </div>
            )}
        </div>
    );
}

export default UserInventoryAddItem;
