// DefiPerso-Frontend\src\components\forms\objectives\ObjectiveCreateForm.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ObjectivesItemsGetAll from '../../shared/ObjectivesItemsGetAll';
import ImageFetcher from "../../shared/images/ImageFetcher";
import { ItemsSearchAndSort } from "../../shared/ItemsSearchAndSort";
import '../../../styles/objectives/objectiveCreateForm.css';


const ObjectiveCreateForm = () => {
    const navigate = useNavigate();

    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('Autres');
    const [description, setDescription] = useState('');
    const [difficulty, setDifficulty] = useState('Facile');
    const [GoldReward, setGoldReward] = useState('0');
    const [itemsList, setItemsList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('name_ASC');
    const [selectedItems, setSelectedItems] = useState([]);


    const handleItemChange = (itemId) => {
        setSelectedItems(prevSelected => {
            const isAlreadySelected = prevSelected.includes(itemId);
            const newSelectedItems = isAlreadySelected
                ? prevSelected.filter(id => id !== itemId)
                : [...prevSelected, itemId];

            return newSelectedItems;
        });
    };

    const handleSubmitCreate = async (e) => {
        e.preventDefault(); // Empêche le rechargement par défaut de la page
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}objectives/new`, {
                objective_title: title,
                objective_category: category,
                objective_description: description,
                objective_difficulty_level: difficulty,
                objective_gold_reward: GoldReward,
                items: selectedItems, // Envoie des items sélectionnés
            });
            navigate('/admin/objectives');   // Redirection vers la liste des objectifs
        }
        catch (error) {
            console.error('Error creating objective :', error);
        }
    }

    // Fonction pour réinitialiser l'input de recherche
    const handleResetClick = () => {
        setSearchTerm('');
    };


    // Application de la fonction de tri et recherche
    const filteredAndSortedItems = ItemsSearchAndSort(itemsList, searchTerm, sortBy);

    return (
        <div>
            <form onSubmit={handleSubmitCreate} className="objectivecreateform-container">
                <h1 className="objectivecreateform-title">Créer un nouvel objectif</h1>
                <div className="objectivecreateform-fields">

                    <label className="objectivecreateform-label">Titre : </label>
                    <input type="text" value={title} onChange={e => setTitle(e.target.value)} className="objectivecreateform-input" />

                    <label className="objectivecreateform-label">Catégorie : </label>
                    <select value={category} onChange={e => setCategory(e.target.value)}>
                        <option value="Travail / Carrière">Travail / Carrière</option>
                        <option value="Sport / Activité physique">Sport / activité physique</option>
                        <option value="Santé / Bien-être">Santé / Bien-être</option>
                        <option value="Scolarité / Apprentissage">Education / Apprentissage</option>
                        <option value="Finances">Finances</option>
                        <option value="Amis / Famille">Amis / Famille</option>
                        <option value="Loisirs / Passions">Loisirs / Passions</option>
                        <option value="Développement Personnel">Développement Personnel</option>
                        <option value="Maison / Organisation">Maison / Organisation</option>
                        <option value="Autres">Autres</option>
                    </select>

                    <label className="objectivecreateform-label">Description : </label>
                    <textarea className="objectivecreateform-textarea"
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        rows="5"
                        cols="30"
                    />

                    <label className="objectivecreateform-label">Difficulté : </label>
                    <select value={difficulty} onChange={e => setDifficulty(e.target.value)} className="objectivecreateform-select">
                        <option value="Facile">Facile</option>
                        <option value="Moyen">Moyen</option>
                        <option value="Difficile">Difficile</option>
                    </select>

                    <label className="objectivecreateform-label">Gain en Or : </label>
                    <input className="objectivecreateform-input" type="number" value={GoldReward} onChange={e => setGoldReward(e.target.value)} />
                </div>

                <div className="objectivecreateform-buttons">
                    <button className="objectivecreateform-btn" type="submit">Créer</button>
                    <button className="objectivecreateform-btn" type="button" onClick={() => navigate('/admin/objectives')}>Annuler</button>
                </div>
            </form>

            <div className="objectivecreateform-container">
                <p>Sélectionner un item ou plusieurs items pour les ajouter comme récompenses pour cet objectif</p>

                {/* Affichage de la liste des items avec recherche et tri */}
                <h2 className="titleObjH2">Liste des items disponibles</h2>

                {/* Tri */}
                <div>
                    <label className="objectivecreateform-label">Trier par </label>
                    <select onChange={(e) => setSortBy(e.target.value)} className="sortSelect">
                        <option value="name_ASC" defaultValue>Nom</option>
                        <option value="category">Catégorie</option>
                        <option value="subcategory">Sous Catégorie</option>
                    </select>
                </div>

                {/* Recherche */}
                <div className="search-and-sort">
                    <label className="objectivecreateform-label">Contenant </label>
                    <input className="objectivecreateform-InputSearch" onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }} value={searchTerm} />

                    <button onClick={handleResetClick}>Réinitialiser la recherche</button>
                </div>

                {/* Affichage des items */}
                <ObjectivesItemsGetAll onItemsFetched={setItemsList} />
                {itemsList.length === 0 ? (
                    // Affichage d'un message si aucun résultat n'est trouvé
                    <p className="noResultsMessage">Aucun item disponible pour les objectifs</p>
                ) : (
                    <div className='ObjTableItems'>
                        <table>
                            <thead className='ObjTableItems-tr'>
                                <tr>
                                    <th>Ajouter</th>
                                    <th>Nom</th>
                                    <th>Description</th>
                                    <th>Categorie</th>
                                    <th>Sous Categorie</th>
                                    <th>Image</th>
                                    <th>Obtention</th>
                                </tr>
                            </thead>
                            <tbody className="objectivesCreateForm-body">
                                {filteredAndSortedItems.map(item => (
                                    <tr className="objectivesCreateForm-row"  key={item.id_item}>
                                        <td className="td-type-1">
                                            <input
                                                type="checkbox"
                                                checked={selectedItems.includes(item.id_item)}
                                                onChange={() => handleItemChange(item.id_item)}
                                            />
                                        </td>
                                        <td>{item.name}</td>
                                        <td>{item.item_description}</td>
                                        <td className="td-type-1">{item.category}</td>
                                        <td className="td-type-1">{item.subcategory}</td>
                                        <td className="td-type-1"><ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}items/images`} imageName={item.image} /></td>
                                        <td className="td-type-1">{item.accessibility}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ObjectiveCreateForm;
