//
// components/pages/UserPages.js
import React from "react";
import {Route, Routes} from "react-router-dom";
import MenuSectionUsers from "../../components/public/MenuUser/MenuSectionUsers.js";
import UserFormInscription from "../../components/forms/users/InscriptionForm.js";
import UserFetchAll from "../../pages/BackOffice/users/UserFetchAll.js";
import UserFetchById from "../../pages/BackOffice/users/UserFetchById";
import UserUpdateById from "../../components/forms/users/UserUpdateById";
import UsersQuestsLinks from "../../pages/BackOffice/users/UsersQuestsLinks";
import UserInventory from "../../pages/BackOffice/users/UserInventory";
import UserInventoryAddItem from "../../pages/BackOffice/users/UserInventoryAddItem";
import UserEquipment from "../../pages/BackOffice/users/UserEquipment";

/**
 *
 * @returns {Element}
 */
const UserPages = () => {

    return (
        <div>
            <MenuSectionUsers/>
            {/*  users/  */}
            <Routes>
                <Route path='/' element={<UserFetchAll/>}/>
                <Route path='/new' element={<UserFormInscription/>}/>
                <Route path='/:id' element={<UserFetchById/>}/>
                <Route path={'/:id/edit'} element={<UserUpdateById/>}/>
                <Route path={'/:id/inventory'} element={<UserInventory/>}/>
                <Route path={'/:id/inventory/new'} element={<UserInventoryAddItem/>}/>
                <Route path={'/:id/equipment'} element={<UserEquipment/>}/>
                <Route path={'/quests-links'} element={<UsersQuestsLinks/>}/>
            </Routes>
        </div>
    );
}

export default UserPages;