// DefiPerso-Frontend\src\components\shared\ObjectivesItemsGetAll.js
import axios from "axios";
import { useEffect, useState } from "react";

const ObjectivesItemsGetAll = ({ onItemsFetched }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    // Récupération et affichage des items contenu dans la DB
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}items/all`);
                const filteredData = response.data.filter(item => item.accessibility === 'Objectif');
                setData(filteredData);
                if (onItemsFetched) {
                    onItemsFetched(filteredData); // Passer les données filtrées au parent
                }
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [onItemsFetched]);

    if (loading) return <p>chargement...</p>;
    if (error) return <p>Erreur : {error.message}</p>;
}

export default ObjectivesItemsGetAll;