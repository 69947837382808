import ItemFetchAll from "./ItemFetchAll";
import { Link } from "react-router-dom";
import '../../../styles/items/itemIndex.css';

//Page Back-office d'accueil de la gestion des items de la DB
export const ItemIndex = () => {
    return (
        <div className="itemindex-container">
            <h1 className="itemindex-title">Gestion des Objets</h1>
            <button className="itemindex-btn">
                <Link to="create" className="itemindex-link">Créer un Objet</Link>
            </button>
            <ItemFetchAll />
        </div>
    );
}