import { useNavigate } from "react-router-dom";
import InscriptionForm from "./InscriptionForm";
import MenuDashBoardBtAccueil from "../../../components/public/MenuDashBoard/MenuDashBoardBtAccueil";



const InscriptionPage = () => {
    const navigate = useNavigate();




    return (
        <>
            <MenuDashBoardBtAccueil />
            <InscriptionForm />
            <button className="inscriptionform-button" onClick={() => {
                navigate('/');
            }}>Retour</button>
        </>
    )
}

export default InscriptionPage;