// Footer.js
import React, { useState, useEffect, useRef } from 'react';
import '../../styles/general/footer.css';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const menuRef = useRef(null);

    const toggleMenu = (event) => {
        event.stopPropagation();
        setShowMenu(!showMenu);
    };

    const handleDisconnection = () => {
        sessionStorage.removeItem('token');
        navigate('/');
        window.location.reload();
    };

    // Effet pour fermer le menu lors d'un clic à l'extérieur
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Vérifie si le clic est à l'extérieur du menu
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        // Ajoute l'écouteur d'événements lorsque le menu est ouvert
        if (showMenu) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        // Nettoie l'écouteur d'événements lorsque le composant est démonté
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showMenu]);

    const handleNavigation = (page) => {
        navigate('/user/' + page);
    }

    const handleNavigateStore = () => {
        navigate('/user/store');
    }

    return (
        <footer className="footer">
            <button
                aria-label="Menu déroulant"
                onClick={toggleMenu}
                className="nav-button"
                data-tooltip="Cliquer pour ouvrir le menu"
            >
                ☰
            </button>
            <button className="nav-button" aria-label="Voir le profil" data-tooltip="Voir le profil">P</button>
            <button className="nav-button" aria-label="Voir les quêtes" data-tooltip="Voir les quêtes">Q</button>

            {showMenu && (
                <div className="menu" ref={menuRef}>
                    <ul>
                        <li onClick={()=>{handleNavigation('store')}}>Store</li>
                        <li onClick={() => {handleNavigation('inventory')}}>Inventaire
                        </li>
                        <li>Paramètres</li>
                        <li>Commencer sur DefiPerso / FAQ</li>
                        <li aria-label="Se déconnecter" onClick={handleDisconnection} style={{ color: '#B6ADAF' }}>Déconnexion</li>
                    </ul>
                </div>
            )}
        </footer>
    );
};

export default Footer;