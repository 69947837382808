import { useNavigate } from "react-router-dom";
import MenuDashBoardBtAccueil from "../../components/public/MenuDashBoard/MenuDashBoardBtAccueil";

const UnauthorizedPage = () => {
    const navigate = useNavigate();

    return (
        <>
            <MenuDashBoardBtAccueil />
                <h1 className="homepage-title">Accès non autorisé</h1>
            <div className="homepage-container">
                <p>Vous n'avez pas les permissions nécessaires pour accéder à cette page.</p>
                <button className="inscriptionform-button" onClick={() => navigate('/')}>
                    Retour à l'accueil
                </button>
            </div>
        </>
    );
}

export default UnauthorizedPage;