// components/Loading.js
import React from 'react';
import '../../../styles/general/loading.css'; // Assurez-vous de créer un fichier CSS pour styliser l'animation

/**
 *
 * @returns {Element}
 */
const Loading = () => {
    return (
        <div className="loading-bar-container">
            <div className="loading-bar">
                <div className="loading-bar-progress"></div>
            </div>
            <p>Chargement...</p>
        </div>
    );
}

export default Loading;