import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import '../../styles/general/homepage.css';
import { jwtDecode } from "jwt-decode";
import bcrypt from "bcryptjs";
import MessageElement from "../../components/shared/message/MessageElement";

const HomePage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [message, setMessage] = useState("");
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {

        const sendWarningMessage = async () => {
            const response = await axios.get(`https://ipinfo.io/ip`);
            const susIp = {
                message : 'token invalide ou falsifié :',
                ipv4: response.data,
            }
            const response2 = await axios.post(`${process.env.REACT_APP_API_URL}sus`, susIp);
            displayMessage(response2.data.message);
            sessionStorage.removeItem("token");

        }

        try{
            if (sessionStorage.getItem('token')) {
                navigateToRoute(sessionStorage.getItem('token'));
            }

        } catch (err) {
            sendWarningMessage();

        }
    }, [])

    const navigateToRoute = (token) => {
        const userAccountType = jwtDecode(token).user.account_type;
        if (bcrypt.compareSync('Admin', userAccountType)) {
            navigate('/admin');
        } else if (bcrypt.compareSync('User', userAccountType)) {
            navigate('/user/home');
        }
    }

    const handleConnection = async () => {
        try {
            const data = { email, password };
            const result = await axios.post(`${process.env.REACT_APP_API_URL}users/connect`, data);

            if (result.data.token) {
                sessionStorage.setItem('token', result.data.token);
                sessionStorage.setItem('id_user', result.data.id_user);
                navigateToRoute(result.data.token);
            } else {
                displayMessage(result.data.message);
                console.warn(result.data.message);
            }

        } catch (err) {
            console.error('Erreur lors de la connexion:', err);
        }
        setEmail('');
        setPassword('');
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
            handleConnection();
        }
    };

    const displayMessage = (msg) => {
        setMessage(msg);
        setShowMessage(true);
    }

    const handleCloseMessage = () => {
        setShowMessage(false);
    }
    
    return (
        <div>
            <h1 className="homepage-title" style={{ textAlign: 'center' }}>Bienvenue sur DefiPerso !</h1>
            <div className="homepageform-container">
                <h2 className="homepage-h2">Pas de compte ? </h2>
                <button
                    onClick={() => navigate('register')}
                    className="homepageform-button"
                >
                    Inscription
                </button>
                <h2 className="homepage-h2 margin-top">Connexion</h2>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="homepageform-input"
                    placeholder="Email"
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="homepageform-input"
                    placeholder="Mot de passe"
                />
                <button
                    onClick={handleConnection}
                    className="homepageform-button"
                >
                    Connexion
                </button>
                {showMessage && (
                <MessageElement message={message} onClose={handleCloseMessage}/>
                )}
            </div>
        </div>
    );
};

export default HomePage;