//
// components/users/InscriptionPage.js

import React, {useState} from "react";
import axios from "axios";
import ButtonGenerique from "../../shared/ButtonUser/ButtonGenerique";
import {useNavigate} from "react-router-dom";
import '../../../styles/users/inscriptionForm.css';
import {hashPassword} from "../../shared/hashPassword";


/**
 *
 * @returns {Element}
 */
const UserFormInscription = () => {
    //username, password, email, firstname, lastname, account_type, tutor_email
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [account_type, setAccountType] = useState('');
    const [tutor_email, setTutorEmail] = useState('');
    const navigate = useNavigate();


    // function pour déclencher l'inscription du formulaire au submit
    // envoie les infos à l'api du backend
    const handleNewUser = async (e) => {
        e.preventDefault();
        try {
            const hashedPassword = await hashPassword(password);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}users/new`, {
                username,
                password: hashedPassword,
                email,
                firstname,
                lastname,
                account_type,
                tutor_email,
            });
            console.log(response.data);
            alert('User created successfully');
            // réinitialiser les champs du formulaire
            setUsername('');
            setPassword('');
            setEmail('');
            setFirstName('');
            setLastName('');
            setAccountType('');
            setTutorEmail('');
            // rediriger vers la page de connexion
            navigate("/admin/users");
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="inscriptionform-container">
            <h2 className="inscriptionform-title">Inscription</h2>
            <form className="inscriptionform-form" onSubmit={handleNewUser}>
                <input
                    type="text"
                    placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    required
                    className="inscriptionform-input"
                />

                <input
                    type="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    className="inscriptionform-input"
                />

                <input
                    type="password"
                    placeholder="Password"
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                    value={password}
                    required
                    className="inscriptionform-input"
                />

                <input
                    type="text"
                    placeholder="Firstname"
                    onChange={(e) => {
                        setFirstName(e.target.value);
                    }}
                    value={firstname}
                    required
                    className="inscriptionform-input"
                />

                <input
                    type="text"
                    placeholder="Lastname"
                    onChange={(e) => {
                        setLastName(e.target.value);
                    }}
                    value={lastname}
                    required
                    className="inscriptionform-input"
                />

                <select
                    onChange={(e) => {
                        // on récupère la value du select et ont set la value tutor_email dans le stat
                        setTutorEmail(e.target.value);
                    }}
                    value={tutor_email}
                    className="inscriptionform-select"
                    required>
                    <option value="">Select Tutor Email</option>
                    <option value="tutor1@example.com">tutor1@example.com</option>
                    <option value="tutor2@example.com">tutor2@example.com</option>
                </select>

                <select
                    onChange={(e) => {
                        // on récupère la value du select et ont set la value account_type dans le stat
                        setAccountType(e.target.value);
                    }}
                    value={account_type}
                    required
                    className="inscriptionform-select"
                >
                    <option value="">Select Account Type</option>
                    <option value="User">User</option>
                    <option value="Admin">Admin</option>
                </select>
                <ButtonGenerique type={'submit'} name={'Ajouter'} className="inscriptionform-button"/>
            </form>
        </div>

    );
};

export default UserFormInscription;

