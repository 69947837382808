//
// components/UserFetchById.js

import React, {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import '../../../styles/users/userFetchById.css';
import ButtonEdit from "../../../components/shared/ButtonUser/ButtonEdit";
import ButtonDelete from "../../../components/shared/ButtonUser/ButtonDelete";
import ButtonGenerique from "../../../components/shared/ButtonUser/ButtonGenerique";

/**
 *
 * @returns {Element}
 */
const UserFetchById = () => {
    const [userById, setUserById] = useState([]);
    // Récupère l'ID de l'URL
    const {id} = useParams();
    // Hook useNavigate pour les redirections de route
    const navigate = useNavigate();

    // Récupère un user par son ID
    useEffect(() => {
        const fetchUserById = async () => {
            try {
                const result = await axios.get(`${process.env.REACT_APP_API_URL}users/${id}`);
                setUserById(result.data[0]);
            } catch (error) {
                console.error("Error fetching users by ID:", error);
            }
        };
        fetchUserById();
    }, [id]);

    // fonction redirection vers page edition users
    const handleEdit = () => {
        navigate(`/admin/users/${id}/edit`);
    };

    // fonction suppression d'un user par son ID
    const handleDelete = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}users/${id}/delete`);
            alert('deleting user successfully');
            navigate("/admin/users");
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    };

    // fonction accesses inventaire users
    const handleInventory = () => {
        navigate(`/admin/users/${id}/inventory`);
    };

    // fonction accesses equipement users
    const handleEquipment = () => {
        navigate(`/admin/users/${id}/equipment`);
    }

    return (
        // username, password, email, firstname, lastname, account_type, tutor_email,
        // birthdate, user_score, weight, height, gender,pose,face, body_shape, skin_color,id_user
        <div className="userfetchbyid-container">

            <div className="userfetchbyid-card">
                <div className="button-to-inventory">
                    <ButtonGenerique onClique={handleInventory} name='Inventaire'/>
                    <h1 className="userfetchbyid-title">User Details</h1>
                    <ButtonGenerique onClique={handleEquipment} name='Equipement'/>
                </div>
                <div className="userfetchbyid-card-content">
                    <div className="userfetchbyid-info-left">
                        <p>Id User :</p>
                        <p>Id teams :</p>
                        <p>User name :</p>
                        <p>Email :</p>
                        <p>Firstname :</p>
                        <p>Lastname :</p>
                        <p>Account Type :</p>
                        <p>Tutor Email :</p>
                        <p>Birthdate :</p>
                        <p>User Score :</p>
                        <p>Weight :</p>
                        <p>Height :</p>
                        <p>Gender :</p>
                        <p>Pose :</p>
                        <p>Face :</p>
                        <p>Body Shape :</p>
                        <p>Skin Color :</p>
                    </div>
                    <div className="userfetchbyid-info-right">
                        <p>{userById.id_user}</p>
                        <p>{userById.id_teams}</p>
                        <p>{userById.username}</p>
                        <p>{userById.email}</p>
                        <p>{userById.firstname}</p>
                        <p>{userById.lastname}</p>
                        <p>{userById.account_type}</p>
                        <p>{userById.tutor_email}</p>
                        <p>{userById.birthdate}</p>
                        <p>{userById.user_score}</p>
                        <p>{userById.weight}</p>
                        <p>{userById.height}</p>
                        <p>{userById.gender}</p>
                        <p>{userById.pose}</p>
                        <p>{userById.face}</p>
                        <p>{userById.body_shape}</p>
                        <p>{userById.skin_color}</p>
                    </div>
                </div>
                <div className="userfetchbyid-buttons">
                    <ButtonEdit onClique={handleEdit}/>
                    <ButtonDelete onClique={handleDelete}/>
                </div>
            </div>

        </div>
    );
}

export default UserFetchById;