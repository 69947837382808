import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TeamsForm from '../../../components/forms/teams/TeamsForm';
import '../../../styles/teams/teamsCreation.css';
import '../../../styles/teams/teamsStyles.css'

const TeamsCreation = () => {
    // Déclaration des états locaux pour gérer les valeurs des champs du formulaire
    const [name, setName] = useState('');
    const [teamScore, setTeamScore] = useState('');
    const [teamLogo, setTeamLogo] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const navigate = useNavigate();

    // Fonction asynchrone pour gérer la soumission du formulaire
    const handleNewTeam = async (e) => {
        e.preventDefault();
        try {
            // Crée un objet FormData pour envoyer les données du formulaire
            const formData = new FormData();
            formData.append('name', name);
            formData.append('team_score', teamScore);
            formData.append('team_logo', imageFile); // Utilisez l'image

            // Envoie une requête POST pour créer une nouvelle équipe
            const response = await axios.post(`${process.env.REACT_APP_API_URL}teams/new`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Team created successfully', response.data);
            // Redirection vers la page teams après la création de l'équipe
            navigate('/admin/teams');
        } catch (err) {
            console.error('Error creating team:', err);
        }
    };

    return (
        <form onSubmit={handleNewTeam}>
            <div className="teamscreation-container">
                <h1 className="teamscreation-title">Ajouter une équipe</h1>
                <TeamsForm
                    name={name}
                    setName={setName}
                    teamScore={teamScore}
                    setTeamScore={setTeamScore}
                    teamLogo={teamLogo}
                    setTeamLogo={setTeamLogo}
                    imageFile={imageFile}
                    setImageFile={setImageFile}
                />
            </div>
        </form>
    );
};

export default TeamsCreation;