import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import ImageFetcher from '../../../components/shared/images/ImageFetcher';
import '../../../styles/teams/teamsStyles.css';

const TeamQuests = () => {
    const { id } = useParams(); // ID de l'équipe passée dans les paramètres de l'URL
    const [team, setTeam] = useState(null); // Pour stocker l'équipe et ses quêtes
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Fonction pour récupérer les quêtes de l'équipe
        const fetchTeamAndQuests = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}teams/${id}/quests`);
                const data = response.data;

                if (data && Array.isArray(data) && data.length > 0) {
                    setTeam(data[0]); // Comme la réponse est un tableau, on prend la première équipe (il devrait y en avoir qu'une avec un ID spécifique)
                } else {
                    setTeam(null);
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des quêtes :", error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchTeamAndQuests();
    }, [id]);

    // Fonction pour attribuer une quête à l'équipe
    const handleAssignQuestToCurrentTeam = () => {
        navigate(`/admin/teams/quests/new`, { state: { teamId: id } });
    };

    // Fonction pour supprimer une quête de l'équipe
    const handleDelete = async (idTeam, idQuest) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer cette quête ?")) {
            try {
                // Appel à l'API pour supprimer la quête
                const response = await axios.delete(`${process.env.REACT_APP_API_URL}teams/delete/${idTeam}/quest/${idQuest}`);

                if (response.status === 200) {
                    // Rafraîchit la page pour mettre à jour la liste des quêtes
                    window.location.reload();
                } else {
                    alert("Erreur lors de la suppression de la quête.");
                }
            } catch (error) {
                console.error("Erreur lors de la suppression de la quête :", error);
                setError(error);
            }
        }
    };

    if (loading) return <p>Chargement des données...</p>;
    if (error) return <p>Erreur : {error.message}</p>;

    const handleSeeQuest = (idQuest) => {
        navigate(`/admin/quests/${idQuest}`);
    };

    return (
        <div className="teamsmanagement-container">
            {team && (
                <>
                    <h1 className="teamsmanagement-title">
                        Quêtes de l'équipe {team.name} <em className="teamstyles-grey">#{team.id_team}</em>
                    </h1>

                    <div className="teamsform-container">
                        <button className="assign-quest-button" onClick={handleAssignQuestToCurrentTeam}>
                            Attribuer une quête à cette équipe
                        </button>
                        {team.Quests.length === 0 ? (
                            <p className="textLeft">Aucune quête pour cette équipe</p>
                        ) : (
                            <ul className="quests-list">
                                {team.Quests.map((quest, index) => (
                                    <li key={quest.id_quest || index} className="quest-item">
                                        <ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}teams/images`} imageName={quest.quest_icon} />
                                        <div className="quest-details">
                                            <p className="quest-title">
                                                <strong>{quest.quest_title}</strong>
                                            </p>
                                            <p className="quest-difficulty-level">
                                                {quest.quest_difficulty_level}
                                            </p>
                                            <p className="quest-description">
                                                <em>{quest.quest_description}</em>
                                            </p>
                                        </div>
                                        <button
                                            aria-label="Voir la quête en détails"
                                            className="buttonSee tooltip"
                                            onClick={() => handleSeeQuest(quest.id_quest)}
                                            data-tooltip="Voir les détails de la quête d'équipe"
                                        >
                                            👁
                                        </button>
                                        <button
                                            aria-label="Supprimer la quête d'équipe"
                                            className="buttonDelete tooltip"
                                            onClick={() => handleDelete(team.id_team, quest.id_quest)}
                                            data-tooltip="Supprimer la quête d'équipe"
                                        >
                                            🗑
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default TeamQuests;