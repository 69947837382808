import React, {useEffect, useState} from "react";
import axios from "axios";
import ImageFetcher from "../../../components/shared/images/ImageFetcher";
import {ItemsSearchAndSort} from "../../../components/shared/ItemsSearchAndSort";
import PaginateElement from "../../../components/shared/pagination/PaginateElement";
import PaginateLimitElement from "../../../components/shared/pagination/PaginateLimitElement";
import {PaginateData} from "../../../components/shared/pagination/PaginateData";
import SearchBar from "../../../components/shared/search/SearchBar";
import '../../../styles/items/itemStore.css'

const StorePage = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const [categorySort, setCategorySort] = useState("");
    const [sortBy, setSortBy] = useState("name_ASC");
    const [searchTerm, setSearchTerm] = useState("");

    const [limit, setLimit] = useState(9);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginatedItems, setPaginatedItems] = useState([]);

    //Recuperation des items du store
    useEffect(() => {
        const fetchStoreItems = async () => {
            try{
                const response = await axios.get(process.env.REACT_APP_API_URL+'items/all');
                const filteredItems = response.data;
                const storeItems = ItemsSearchAndSort(filteredItems,'magasin', 'accessibility');
                setData(storeItems);

            } catch (error) {
                setError(error);
            }finally {
                setLoading(false);
            }
        }
        fetchStoreItems();
    }, []);

    // creation de la pagination des element selon le nombre d'element a afficher, et les criteres de recherche
    useEffect(() => {
        const showFilteredItems = (sortedList) => {

            const paginatedData = PaginateData(sortedList, limit);

            setTotalPages(paginatedData.totalPages);

            if( paginatedData.totalPages > 0 && currentPage > paginatedData.totalPages){
                setCurrentPage(paginatedData.totalPages);
            }
            setPaginatedItems(paginatedData.data);
        };


        showFilteredItems(ItemsSearchAndSort(
            ItemsSearchAndSort(data,categorySort,'category'),
            searchTerm,
            sortBy
            ));

    }, [loading, limit, categorySort, sortBy, searchTerm]);



    //PaginationLimitElement : Selection de nombre d'element a afficher par page
    const handleNewLimit = (newLimit) => {
        setLimit(newLimit);
    }

    //PaginateElement :  Fonction pour passer à la page précédente
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    //PaginateElement : Fonction pour passer à la page suivante
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    //
    const handleCategorySelection = (sort) => {
        setCategorySort(sort);
    }


    const handleAdvanceSearch = (prop) => {
        setSortBy(prop.sort);
        setSearchTerm(prop.searchTerm);
    }

    if (loading) return <p>chargement...</p>;
    if (error) return <p>Erreur : {error.message}</p>;


    return (
        <>
            <div className='Container-Title'>
            <h2>Store</h2>
            </div>
            <div className='Store-Container'>
                <div className='Side-Menu'>
                    <p>Categorie</p>
                    <ul>
                        <li onClick={()=>{handleCategorySelection('')}}>Tous</li>
                        <li onClick={()=>{handleCategorySelection('Equipement')}}>Equipement</li>
                        <li onClick={()=>{handleCategorySelection('Divers')}}>Divers</li>
                        <li onClick={()=>{handleCategorySelection('Compagnons')}}>Compagnons</li>
                    </ul>
                </div>

                <div className='Main-Content'>
                    <div className='Store-Search'>
                        <SearchBar output={handleAdvanceSearch} />
                        <PaginateLimitElement newLimit={handleNewLimit} options={[9,18,36]}/>
                    </div>

                    <div className='Items-Grid'>
                        {paginatedItems[currentPage-1] && paginatedItems[currentPage-1].map((item) => (
                            <div key={item.id_item} className='Item-Card'>
                                <ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}items/images`} imageName={item.image}/>
                                <div>
                                    <div className='Item-Name'>{item.name}</div>
                                    <div className='Item-Price'>{item.price} pieces</div>
                                </div>
                                <div>
                                    <button>🛒</button>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/*Bouttons de pagination*/}
                    <PaginateElement previousPage={handlePreviousPage} nextPage={handleNextPage} currentPage={currentPage} totalPages={totalPages} />

                </div>
            </div>
        </>
    )
}

export default StorePage;