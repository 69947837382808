import React from 'react';
import '../../styles/users/UserInventory.css';
import ImageFetcher from "./images/ImageFetcher";

/**
 * InventoryGrid - Un composant pour afficher l'inventaire des utilisateurs et les détails des objets.
 *
 * @param {Array} userInventory - La liste des objets de l'inventaire de l'utilisateur.
 * @param {Object|null} selectedItemDetails - Les détails de l'objet sélectionné.
 * @param {Function|null} handleDetails - La fonction pour gérer la sélection d'un objet et afficher ses détails.
 * @param {Function|null} handleDeleteItem - La fonction pour supprimer un objet de l'inventaire de l'utilisateur.
 * @returns {JSX.Element} Le composant InventoryGrid (display de l'inventaire).
 * @constructor
 */
const InventoryGrid = ({userInventory, selectedItemDetails, handleDetails = null, handleDeleteItem = null}) => {
    return (
        <div className="inventory-grid">
            <div className="inventory-left">
                {userInventory.map((item) => (
                    <div key={item.id_item} className="inventory-item" onClick={() => handleDetails(item.id_item)}>
                        <p>{item.Item.name}</p>
                        <div className='image-item-inventory'>
                            <ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}items/images`}
                                          imageName={item.Item.image}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className="inventory-right">
                <p>DETAILS OBJET</p>
                {selectedItemDetails && (
                    <div className="item-extra-details">
                        <p>Description : <span>{selectedItemDetails.Item.item_description}</span></p>
                        <p>Prix : <span>{selectedItemDetails.Item.price}</span></p>
                        <p>Catégorie : <span>{selectedItemDetails.Item.category}</span></p>
                        <p>Sous-catégorie : <span>{selectedItemDetails.Item.subcategory}</span></p>
                        <p>Type : <span>{selectedItemDetails.Item.accessibility}</span></p>
                        <ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}items/images`}
                                      imageName={selectedItemDetails.Item.image}
                        />
                        <button className='button-inventory button-delete'
                                onClick={() => handleDeleteItem(selectedItemDetails.Item.id_item)}>
                            X
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InventoryGrid;
