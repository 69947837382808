import axios from "axios";

/**
 *
 * @param apiUrl Lien vers l'api
 * @param data paquet de donnees a envoyer vers l'api
 * @returns {Promise<void>}
 */
//Envoi d'un nouvel items vers la DB
const PostData = async (apiUrl , data) => {
    await axios.post(`${apiUrl}`, data);
}

export default PostData;