import React, { useEffect, useState } from "react";
import '../../../styles/users/inscriptionForm.css';
import PostData from "../../../components/shared/PostData";
import { useNavigate } from "react-router-dom";

const InscriptionForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [accountTypeSelected, setAccountTypeSelected] = useState(false);
    const [accountType, setAccountType] = useState('User');
    const [tutorEmail, setTutorEmail] = useState('none');

    const navigate = useNavigate();

    // Fonction pour formater et mettre à jour les noms avant l'envoi
    const handleNewUser = async (e) => {
        e.preventDefault();

        // Appel de la fonction pour formater le prénom et le nom
        const formattedFirstname = firstname.charAt(0).toUpperCase() + firstname.slice(1).toLowerCase();
        const formattedLastname = lastname.toUpperCase();

        // Mise à jour des valeurs formatées dans les champs avant l'envoi
        setFirstName(formattedFirstname);
        setLastName(formattedLastname);

        // Ensuite, on attend que React mette à jour le state et on soumet les données
        await PostData(`${process.env.REACT_APP_API_URL}users/new`, {
            username,
            password,
            email,
            firstname: formattedFirstname,
            lastname: formattedLastname,
            account_type: accountType,
            tutor_email: tutorEmail,
        });

        // Réinitialiser les champs après soumission
        setUsername('');
        setPassword('');
        setEmail('');
        setFirstName('');
        setLastName('');
        console.log("L'utilisateur a été créé avec succès !");
        setTimeout(() => {
            navigate('/')
        }, 500);
    };

    const handleTypeAccount = async (e) => {
        e.preventDefault();
        setAccountTypeSelected(true);
        setAccountType(e.target.value);
    };

    useEffect(() => {
        if (accountType === '') {
            setAccountTypeSelected(false);
        }
        if (accountType === 'User' || accountType === 'EPIDE') {
            setAccountTypeSelected(true);
        }
    }, [accountType]);

    // Fonction pour gérer la soumission avec la touche Entrée
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === 'NumpadEnter') {
            handleNewUser(e);
        }
    };

    return (
        <div className="inscriptionform-container">
            <h2 className="inscriptionform-title">Inscription</h2>
            <h3>Sélectionner votre type de compte</h3>
            <select value={accountType} onChange={handleTypeAccount}>
                <option value="EPIDE">EPIDE</option>
                <option value="User">LIBRE</option>
            </select>
            {accountTypeSelected && (
                <form className="inscriptionform-form" onSubmit={handleNewUser}>
                    <p>Compte {accountType} sélectionner</p>
                    <input
                        type="text"
                        placeholder="Pseudo"
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                        required
                        className="inscriptionform-input"
                        onKeyDown={handleKeyDown}
                    />

                    <input
                        type="email"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                        className="inscriptionform-input"
                        onKeyDown={handleKeyDown}
                    />

                    <input
                        type="password"
                        placeholder="Mot de passe"
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        value={password}
                        required
                        className="inscriptionform-input"
                        onKeyDown={handleKeyDown}
                    />

                    <input
                        type="text"
                        placeholder="Prénom"
                        onChange={(e) => {
                            setFirstName(e.target.value);
                        }}
                        value={firstname}
                        required
                        className="inscriptionform-input"
                        onKeyDown={handleKeyDown}
                    />

                    <input
                        type="text"
                        placeholder="Nom"
                        onChange={(e) => {
                            setLastName(e.target.value);
                        }}
                        value={lastname}
                        required
                        className="inscriptionform-input"
                        onKeyDown={handleKeyDown}
                    />

                    {accountType === "EPIDE" && (
                        <select
                            onChange={(e) => {
                                setTutorEmail(e.target.value);
                            }}
                            value={tutorEmail}
                            className="inscriptionform-select"
                            required>
                            <option value="">Select Tutor Email</option>
                            <option value="tutor1@example.com">tutor1@example.com</option>
                            <option value="tutor2@example.com">tutor2@example.com</option>
                        </select>
                    )}

                    <button type='submit' name='Ajouter' className="inscriptionform-button">Ajouter</button>
                </form>
            )}
        </div>
    );
};

export default InscriptionForm;