// DefiPerso-Frontend\src\pages\BackOffice\objectives\ObjectivesGetAll.js
import axios from "axios";
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { truncateText } from '../../../components/shared/TruncateText';
import '../../../styles/objectives/objectivesGetAll.css';


const ObjectivesGetAll = () => {
    const [objectives, setObjectives] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('id_objective_DESC'); // Tri par défaut réglé sur title
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1); // Page actuelle
    const [totalPages, setTotalPages] = useState(1); // Total de pages
    const [totalObjectives, setTotalObjectives] = useState(0);
    const [expandedObjectiveId, setExpandedObjectiveId] = useState(null);
    const limit = 10; // Nombre d'objectifs par page
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            try {
                // Inclure le searchTerm et le sortBy dans la requête API
                const response = await axios.get(`${process.env.REACT_APP_API_URL}objectives/all?page=${currentPage}&limit=${limit}&searchTerm=${searchTerm}&sortBy=${sortBy}`);
                setObjectives(response.data.data);
                setTotalPages(response.data.totalPages); // Mettre à jour le nombre total de pages
                setTotalObjectives(response.data.totalObjectives); // Mettre à jour le nombre total d'objectifs
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [currentPage, searchTerm, sortBy]);


    // Fonction pour supprimer un objectif
    const handleDelete = async (id_objective) => {
        if (window.confirm('Etes-vous sûr(e) de vouloir supprimer cet objectif ?')) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}objectives/${id_objective}/delete`, { method: 'DELETE' })
                refreshObjectives();
            } catch (error) {
                console.error('Error deleting objective:', error);
            }
        }
    };


    // Fonction pour mettre à jour la liste après suppression ou mise à jour
    const refreshObjectives = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}objectives/all?page=${currentPage}&limit=10`);
            setObjectives(response.data.data);
            setTotalObjectives(response.data.totalObjectives);
        } catch (error) {
            console.error('Error refreshing objectives:', error);
        }
    };


    // Naviger vers la page de création
    const handleCreateClick = () => {
        navigate('/admin/objectives/create');
    };


    // Fonction pour naviguer vers la page d'affichage des objectifs triés par utilisateurs
    const handleObjectiveSortByUserClick = () => {
        navigate('/admin/objectives/sortUserObjective');
    };


    // Fonction pour déplier ou réduire le texte
    const toggleExpand = (objectiveId) => {
        if (expandedObjectiveId === objectiveId) {
            setExpandedObjectiveId(null); // Réduire le texte si c'est déjà déplié
        } else {
            setExpandedObjectiveId(objectiveId); // Déplier le texte pour l'objectif en cours
        }
    };


    // Fonction pour passer à la page précédente
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    // Fonction pour passer à la page suivante
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };


    // Fonction pour réinitialiser l'input de recherche
    const handleResetClick = () => {
        setSearchTerm('');
        setCurrentPage(1); // Réinitialiser la page actuelle à 1
    };


    return (
        <div className="objectivesgetall-container">
            <h1 className="objectivesgetall-title">Liste des Objectifs</h1>

            <h2 className="totalObjectives"> Nombre d'objectifs disponibles : <span className="nbTotal">{totalObjectives}</span></h2>

            <p id="ObjectiveExplain">Cliquez sur le titre pour voir les détails</p>

            {/* Tri */}
            <div>
                <label className="objectivecreateform-label">Trier par </label>
                <select onChange={(e) => setSortBy(e.target.value)} className="sortSelect">
                    <option value="title">Titre</option>
                    <option value="category">Catégorie</option>
                    <option value="description">Description</option>
                    <option value="difficulty">Difficulté</option>
                    <option value="gold_reward_ASC">Gain d'Or croissant</option>
                    <option value="gold_reward_DESC">Gain d'Or décroissant</option>
                    <option value="id_objective_ASC">ID par ordre croissant</option>
                    <option value="id_objective_DESC">ID par ordre décroissant</option>
                </select>
            </div>

            {/* Recherche */}
            <div className="objectivecreateform-label">
                <label>Contenant </label>
                <input
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setCurrentPage(1);
                    }} value={searchTerm} />

                <button onClick={handleResetClick}>Réinitialiser la recherche</button>
            </div>

            <div className="ObjectiveGetAll-Nav-btn">
                <button className="objectivesgetall-sort-btn" onClick={handleCreateClick}>Créer un objectif</button>
                <button className="objectivesgetall-sort-btn" onClick={handleObjectiveSortByUserClick}>Voir la page Objectifs triés par utilisateurs</button>
            </div>

            {loading ? (
                <p>Chargement...</p>
            ) : error ? (
                <p>Erreur: {error}</p>
            ) : (
                <div>
                    <table className="objectivesgetall-table">
                        <thead>
                            <tr className="objectivesgetall-header">
                                <th>Id</th>
                                <th>Titre</th>
                                <th>Catégorie</th>
                                <th>Description</th>
                                <th>Difficulté</th>
                                <th>Gain d'Or</th>
                                <th>Gain d'Item</th>
                                <th>Users</th>
                                <th>Actions</th>
                            </tr>
                        </thead>

                        <tbody className="objectivesgetall-body">
                            {objectives.map(objective => (
                                <tr className="objectivesgetall-row" key={objective.id_objective}
                                >
                                    <td className="objectivesgetall-desc">{objective.id_objective}</td>

                                    <td className="objectivesgetall-desc-title">
                                        <Link to={`/admin/objectives/${objective.id_objective}/details`}
                                            className="objectivesgetall-link">{objective.objective_title}</Link>
                                    </td>

                                    <td className="objectivesgetall-desc-2">{objective.objective_category}</td>

                                    <td className="objectivesgetall-desc-2">
                                        {expandedObjectiveId === objective.id_objective
                                            ? objective.objective_description
                                            : truncateText(objective.objective_description, 30)}
                                        {objective.objective_description.length > 30 && (
                                            <button className="toggleExpand" onClick={() => toggleExpand(objective.id_objective)}>
                                                {expandedObjectiveId === objective.id_objective ? "Voir moins" : "Voir plus"}
                                            </button>
                                        )}
                                    </td>

                                    <td className="objectivesgetall-desc-1">{objective.objective_difficulty_level}</td>

                                    <td className="objectivesgetall-desc-1">{objective.objective_gold_reward}</td>

                                    {/* Affichage des items associés */}
                                    <td className="objectivesgetall-desc-3">
                                        {objective.Items && objective.Items.length > 0 ? (
                                            <div className="ObjectiveItemsContainer">
                                                {objective.Items.map((item, index) => (
                                                    <ul key={index} className="itemDetails">
                                                        <li>{item.name}</li>
                                                    </ul>
                                                ))}
                                            </div>
                                        ) : (
                                            <p className="NoAnswer">Aucun item associé</p>
                                        )}
                                    </td>

                                    {/* Affichage des utilisateurs associés */}
                                    <td className="objectivesgetall-desc-3">
                                        {objective.Users && objective.Users.length > 0 ? (
                                            <div className="ObjectiveItemsContainer">
                                                {objective.Users.map((user, index) => (
                                                    <div key={index}>
                                                        <ul>
                                                            <li>
                                                                <p className="ObjectiveUserListName">
                                                                    <Link to={`/admin/objectives/${user.id_user}/detailsUserObjective`}
                                                                        className="objectivesgetall-link">
                                                                        {user.lastname.toUpperCase()} {user.firstname}
                                                                    </Link>
                                                                </p>
                                                                <p>({user.username}) </p>
                                                                <p >{user.account_type}</p>
                                                                <p>{user.tutor_email}</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <p className="NoAnswer">Aucun utilisateur associé</p>
                                        )}
                                    </td>

                                    <td>
                                        <p><button className="objectivesgetall-btn-action"
                                            onClick={() => navigate(`/admin/objectives/${objective.id_objective}/edit`)}>Modifier
                                        </button></p>
                                        <p><button className="objectivesgetall-btn-action"
                                            onClick={() => handleDelete(objective.id_objective)}>Supprimer
                                        </button></p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Pagination */}
                    <div className="objectivesPagination">
                        {currentPage > 1 && (
                            <button onClick={handlePreviousPage}>Précédent</button>
                        )}

                        <span>Page <span id="currentPage">{currentPage}</span> sur {totalPages}</span>

                        {currentPage < totalPages && (
                            <button onClick={handleNextPage}>Suivant</button>
                        )}
                    </div>
                </div>
            )
            }
        </div >
    );
};

export default ObjectivesGetAll;