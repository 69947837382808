// DefiPerso\frontend\src\components\shared\ObjectiveSearchAndSort.js

// Filtrage basé sur le terme de recherche (par champ correspondant)
const advanceSearch = (items, searchField, searchTerm ) => {
     return items.filter((item) =>
        (item[searchField].toLowerCase()).includes(searchTerm.toLowerCase())
    )
}

export const ItemsSearchAndSort = (items, searchTerm, sortBy) => {
    let result = items;

    // Tri en fonction du critère sélectionné"
    switch (sortBy) {
        case 'id':
            result.sort((a, b) => parseInt(a.id_item) - parseInt(b.id_item)); // Tri par id
            break;

        case 'name_ASC':
            result.sort((a, b) => a.name.localeCompare(b.name)); // Tri alphabétique par titre
            if(searchTerm){
                result = advanceSearch(result, 'name', searchTerm);
            }
            break;

        case 'name_DESC':
            result.sort((a, b) => b.name.localeCompare(a.name)); // Tri alphabétique par titre
            if(searchTerm){
                result = advanceSearch(result, 'name', searchTerm);
            }
            break;

        case 'category':
            result.sort((a, b) => a.category.localeCompare(b.category)); // Tri par catégorie
            if(searchTerm){
                result = advanceSearch(result, 'category', searchTerm);
            }
            break;

        case 'subcategory':
            result.sort((a, b) => a.subcategory.localeCompare(b.subcategory)); // Tri par sous-catégorie
            if(searchTerm){
                result = advanceSearch(result, 'subcategory', searchTerm);
            }
            break;

        case 'accessibility':
            result.sort((a, b) => a.accessibility.localeCompare(b.accessibility)); // Tri par type
            if(searchTerm){
                result = advanceSearch(result, 'accessibility', searchTerm);
            }
            break;

        case 'price_ASC':
            result.sort((a, b) => parseInt(a.price) - parseInt(b.price)); // Tri par priorité (plus élevée en premier)
            break;

        case 'price_DESC':
            result.sort((a, b) => parseInt(b.price) - parseInt(a.price)); // Tri par priorité (plus élevée en premier)
            break;

        default:
            result.sort((a, b) => parseInt(a.id_item) - parseInt(b.id_item)); // Tri par id
            break;
    }

    return result;
};

