import React, { useState } from 'react';
import axios from 'axios';
import '../../../styles/quests/updateQuest.css';

const UpdateQuest = () => {
    const [id, setId] = useState('');
    const [questTitle, setTitle] = useState('');
    const [questDescription, setDescription] = useState('');
    const [questIcon, setIcon] = useState('');
    const [questDifficultyLevel, setDifficultyLevel] = useState('');
    const [questGoldReward, setGoldReward] = useState('');

    const handleFetchQuest = async () => {
        try {
            const result = await axios.get(`${process.env.REACT_APP_API_URL}quests/${id}`);
            const quest = result.data;
            setTitle(quest.quest_title);
            setDescription(quest.quest_description);
            setIcon(quest.quest_icon);
            setDifficultyLevel(quest.quest_difficulty_level);
            setGoldReward(quest.quest_gold_reward);
        } catch (error) {
            console.error('Error fetching quests:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.put(`${process.env.REACT_APP_API_URL}quests/${id}`, {
            quest_title: questTitle,
            quest_description: questDescription,
            quest_icon: questIcon,
            quest_difficulty_level: questDifficultyLevel,
            quest_gold_reward: questGoldReward,
        });
    };

    return (
        <div className="update-quest-container">
            <h2 className="update-quest-title">Update Quest</h2>
            <form onSubmit={handleSubmit} className="update-quest-form">
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Quest ID"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        required
                    />
                    <button type="button" onClick={handleFetchQuest} className="fetch-quest-button">Fetch Quest</button>
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Title"
                        value={questTitle}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <textarea
                        placeholder="Description"
                        value={questDescription}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Icon"
                        value={questIcon}
                        onChange={(e) => setIcon(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Difficulty Level"
                        value={questDifficultyLevel}
                        onChange={(e) => setDifficultyLevel(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="number"
                        placeholder="Gold Reward"
                        value={questGoldReward}
                        onChange={(e) => setGoldReward(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="submit-button">Update Quest</button>
            </form>
        </div>
    );
};

export default UpdateQuest;