import {useEffect, useState} from "react";
import axios from "axios";


const ImageFetcher = ({apiUrl, imageName}) => {
    const [imageSrc, setimageSrc] = useState(null);

    useEffect(() => {
        const fetchImage = async () => {
            try{
                let response = '';
                //Requete de l'image a l'API, recoit une reponse de type Binary Large OBject.
                if(imageName === ''){
                    response = await axios.get(`${apiUrl}/null`, {responseType: 'blob',});
                } else {
                    response = await axios.get(`${apiUrl}/${imageName}`, {responseType: 'blob',});
                }
                if(response) {
                    const imageURL = URL.createObjectURL(response.data);
                    setimageSrc(imageURL);
                }

            } catch (err) {
                console.log('Error on fetching image : ',err);
            }
        };

        fetchImage();

    }, [imageName]);

    useEffect(() => {
        return () => {
            if(imageSrc){
                URL.revokeObjectURL(imageSrc);
            }
        }
    }, [imageSrc]);

    return (
        <>
        {imageSrc ? <img src={imageSrc} alt={imageName} width={100}/> : <p>chargement de l'image {imageName}</p>}
        </>
    )
}

export default ImageFetcher;