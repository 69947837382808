//
// component/UserEquipment.js

import React, {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import ImageFetcher from "../../../components/shared/images/ImageFetcher";


const UserEquipement = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    //Liste des items de categorie 'Equipement'
    const [itemsData, setItemsData] = useState([]);
    //Equipement 'Actif' de l'utilisateur.
    const [equipmentData, setEquipmentData] = useState([]);

    //State de debug pour attribution d'un equipement a un utilisateur
    const [equipmentAttribution, setEquipmentAttribution] = useState(false);

    useEffect(() => {

        const fetchUserEquipment = async () => {
            const result = await axios.get(`${process.env.REACT_APP_API_URL}users/${id}/equipment`);
            setEquipmentData(result.data);
        }

        fetchUserEquipment();
    }, []);

    useEffect(() => {
        const fetchEquipmentItems = async () => {
            const result = await axios.get(`${process.env.REACT_APP_API_URL}items`);
            const filteredItems = result.data.filter(item => item.category === 'Equipement');
            setItemsData(filteredItems);
        }

        fetchEquipmentItems();
    }, []);

    const handleAttribution = async (item_id) => {

        console.log("attribution item : ", item_id, " to user : ", id);
        const user_equipment = {
            item_id: item_id,
        }
        await axios.post(`${process.env.REACT_APP_API_URL}users/${id}/equipment/new`, user_equipment);
        window.location.reload();
    }

    const handleDeleteEquipment = async (item_id) => {
        await axios.delete(`${process.env.REACT_APP_API_URL}users/${id}/equipment/delete/${item_id}`);
        window.location.reload();
    }

    return (
        <>
            {
                equipmentData.map((equipment, index) => (
                    <div key={index}>
                        <h3>{equipment.Item.subcategory}</h3>
                        <p>{equipment.Item.name}</p>
                        <ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}items/images`}
                                      imageName={equipment.Item.image}/>
                        <button onClick={() => {
                            handleDeleteEquipment(equipment.Item.id_item)
                        }}>Supprimer equipement
                        </button>
                    </div>
                ))
            }

            <button onClick={() => {
                equipmentAttribution ? setEquipmentAttribution(false) : setEquipmentAttribution(true)
            }}>
                {!equipmentAttribution ? "Ouvrir le tableau d'equipement" : "Fermer le tableau d'equipement"} (Debug)
            </button>
            <button onClick={() => {
                navigate(`/admin/users/${id}`)
            }}>Retour
            </button>

            {equipmentAttribution && (
                <table>
                    <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Image</th>
                        <th>Emplacement</th>
                    </tr>
                    </thead>
                    <tbody>
                    {itemsData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>
                                <ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}items/images`}
                                              imageName={item.image}/>
                            </td>
                            <td>{item.subcategory}</td>
                            <td>
                                <button onClick={() => {
                                    handleAttribution(item.id_item)
                                }}>Attribuer
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </>
    )
}

export default UserEquipement;

