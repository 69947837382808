import React, { useState } from 'react';
import axios from 'axios';
import '../../../styles/quests/questById.css';

const QuestById = () => {
    const [id, setId] = useState('');
    const [quest, setQuest] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await axios.get(`${process.env.REACT_APP_API_URL}quests/${id}`);
            setQuest(result.data);
        } catch (error) {
            console.error('Error fetching quest:', error);
        }
    };

    return (
        <div className="quest-by-id-container">
            <h2 className="quest-by-id-title">Get Quest By ID</h2>
            <form onSubmit={handleSubmit} className="quest-by-id-form">
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Quest ID"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        required
                    />
                    <button type="submit" className="button">Get Quest</button>
                </div>
            </form>
            {quest && (
                <div className="quest-details">
                    <h3>{quest.quest_title}</h3>
                    <p>{quest.quest_description}</p>
                    <p><strong>Icon:</strong> {quest.quest_icon}</p>
                    <p><strong>Difficulty:</strong> {quest.quest_difficulty_level}</p>
                    <p><strong>Gold Reward:</strong> {quest.quest_gold_reward}</p>
                </div>
            )}
        </div>
    );
};

export default QuestById;