import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import '../../../styles/teams/teamsStyles.css';
import '../../../styles/teams/teamsCreation.css';

const TeamQuestCreation = ({ questId }) => {
    const { id } = useParams();
    const location = useLocation();
    const teamId = location.state?.teamId || id;
    const [teams, setTeams] = useState([]);
    const [quests, setQuests] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(teamId); // équipe sélectionnée si fait via TeamQuests
    const [selectedQuest, setSelectedQuest] = useState(questId || null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTeams = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}teams/all`); // Requête pour obtenir toutes les équipes
                setTeams(response.data); // Met à jour l'état avec les équipes
            } catch (error) {
                console.error("Erreur lors de la récupération des équipes :", error);
                setError(error);
            }
        };

        const fetchQuests = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}quests`); // Requête pour obtenir toutes les quêtes
                setQuests(response.data); // Met à jour l'état avec les quêtes
            } catch (error) {
                console.error("Erreur lors de la récupération des quêtes :", error);
                setError(error);
            }
        };

        const fetchData = async () => {
            setLoading(true); // Démarre l'indicateur de chargement
            await Promise.all([fetchTeams(), fetchQuests()]); // Appelle les deux fonctions en parallèle
            setLoading(false); // Arrête l'indicateur de chargement
        };

        fetchData();
    }, []);

    const handleAssignQuest = async () => {
        if (!selectedTeam || !selectedQuest) {
            alert("Veuillez sélectionner une équipe et une quête.");
            return;
        }

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}teams/${selectedTeam}/quests/new`, {
                id_team: selectedTeam,
                id_quest: selectedQuest,
            });
            navigate(`/admin/teams/${selectedTeam}/quests`);
        } catch (error) {
            console.error("Erreur lors de l'attribution de la quête :", error);
            setError(error);
        }
    };

    if (loading) return <p>Chargement des données...</p>;
    if (error) return <p>Erreur : {error.message}</p>;
    return (
        <div className="teamscreation-container">
            <h1 className="teamscreation-title">Attribuer une quête à une équipe</h1>
            <div className="teamsform-container">
                <div>
                    <select
                        id="team-select"
                        value={selectedTeam}
                        onChange={(e) => setSelectedTeam(e.target.value)}
                    >
                        <option value="">Choisir une équipe</option>
                        {teams.map((team) => (
                            <option key={team.id_team} value={team.id_team}>
                                #{team.id_team} {team.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <select
                        id="quest-select"
                        value={selectedQuest}
                        onChange={(e) => setSelectedQuest(e.target.value)}
                    >
                        <option value="">Choisir une quête</option>
                        {quests.map((quest) => (
                            <option key={quest.id_quest} value={quest.id_quest}>
                                #{quest.id_quest} {quest.quest_title} ({quest.quest_difficulty_level})
                            </option>
                        ))}
                    </select>
                </div>
                <button className="teamsform-button" onClick={handleAssignQuest}>Attribuer la quête</button>
            </div>
        </div>
    );
};

export default TeamQuestCreation;