import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import bcrypt from "bcryptjs";

const ProtectedRoute = ({ allowedRoles }) => {
    const token = sessionStorage.getItem('token');
    if (!token) {
        return <Navigate to="/" />; // Si pas de token, redirige vers la page de connexion (qui est localhost:3000 actuellement)
    }

    try {
        const decodedToken = jwtDecode(token);
        // Vérifie si le rôle décodé du token est dans les rôles autorisés
        if (!bcrypt.compareSync(allowedRoles[0],decodedToken.user.account_type)) {
            return <Navigate to="/unauthorized" />; // Si rôle non autorisé, redirige vers une page d'accès non autorisé
        }

        return <Outlet />; // Si tout est bon, permet l'accès à la route protégée
    } catch (error) {
        console.error('Invalid token:', error);
        return <Navigate to="/" />; // Si le token est invalide, redirige vers la page de connexion (qui est localhost:3000 actuellement)
    }
};

export default ProtectedRoute;