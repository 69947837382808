//
// components/UserFetchAll.js

import React, {useEffect, useState} from "react";
import axios from "axios";
import '../../../styles/users/userFetchAll.css';
import {useNavigate} from "react-router-dom";

/**
 *
 * @returns {Element}
 */
const UserFetchAll = () => {
    const [users, setUsers] = useState([]);
    const [usersFiltered, setUsersFiltered] = useState(users);

    const navigate = useNavigate();

    // useEffect pour fetcher les données au démarrage du composant et à chaque modification
    useEffect(() => {
        // fetch request récupère tous les utilisateurs de l'api
        const fetchUsers = async () => {
            try {
                const result = await axios.get(`${process.env.REACT_APP_API_URL}users/all`);
                // Mettre à jour l'état avec les données récupérées
                setUsersFiltered(result.data);
                setUsers(result.data);
            } catch (error) {
                console.error('Error fetching quests:', error);
            }
        };
        fetchUsers();
    }, []);

    // fonction pour naviguer vers le composant UserFetchById
    const handleUserById = (id) => {
        // le hook useNavigate pour naviguer vers le composant
        // UserFetchById avec l'ID comme paramètre de l'URL
        navigate(`/admin/users/${id}`);
    }

    // fonction pour chercher des utilisateurs dans la liste
    const handleSearch = (search) => {
        // Mettre à jour l'état des utilisateurs filtrés avec la recherche effectuée
        const newData =
            users.filter((users) => users.username.toLowerCase().includes(search.toLowerCase()));
        setUsersFiltered(newData);
    }

    const [sortConfig, setSortConfig] =
        useState({key: null, direction: 'ascending', icon: '▲'});

    // fonction tri dynamique du tableau

    const handleSort = (key) => {
        // Déterminer la direction de tri : si la clé de tri actuelle est la même que la clé
        // sélectionnée et que la direction est 'ascending', alors changer la direction à 'descending'.
        // Sinon, définir la direction à 'ascending'.
        let direction = 'ascending';
        let icon = '▲';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
            icon = '▼';
        }

        // Mettre à jour l'état de configuration du tri avec la nouvelle clé et direction
        setSortConfig({key, direction, icon});

        // Créer une copie du tableau usersFiltered pour éviter de modifier l'état directement
        const sortedUsers = [...usersFiltered].sort((a, b) => {
            // Comparer les valeurs des objets a et b selon la clé de tri
            // ** Si la valeur de a[key] est inférieure à celle de b[key],
            // cela signifie que a doi venir avant b dans l'ordre tri.
            // ** Si la direction de tri est ascendante ('ascending'), on retourne -1,
            // ce qui indique à la fonction de tri que a doi être avant b.
            // ** Si la direction de tri est descendante ('descending'), on retourne 1,
            // ce qui indique à la fonction de tri que a doi être après b.
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        // Mettre à jour l'état des utilisateurs filtrés avec le tableau trié
        setUsersFiltered(sortedUsers);
    };

    return (
        // username, password, email, firstname, lastname, account_type, tutor_email,
        // birthdate, user_score, weight, height, gender,pose,face, body_shape, skin_color,id_user
        <div className="userfetchall-container">
            <h1 className="userfetchall-title">Liste des utilisateurs</h1>
            <input
                type="text"
                className="userfetchall-search"
                onChange={(e) => {
                    handleSearch(e.target.value);
                }}
                placeholder={'Username'}
            />
            <table className="userfetchall-table">
                <thead>
                <tr>
                    <th onClick={() => handleSort('id_user')}>ID User - {sortConfig.icon}</th>
                    <th onClick={() => handleSort('username')}>Username - {sortConfig.icon}</th>
                    <th onClick={() => handleSort('email')}>Email - {sortConfig.icon}</th>
                    <th onClick={() => handleSort('firstname')}>Firstname - {sortConfig.icon}</th>
                    <th onClick={() => handleSort('lastname')}>Lastname - {sortConfig.icon}</th>
                    <th onClick={() => handleSort('account_type')}>Account Type - {sortConfig.icon}</th>
                    <th onClick={() => handleSort('tutor_email')}>Tutor Email - {sortConfig.icon}</th>
                </tr>
                </thead>
                <tbody>
                {usersFiltered.map((user) => (
                    <tr
                        key={user.id_user}
                        className="userfetchall-row"
                        onClick={() => {
                            handleUserById(user.id_user);
                        }}
                    >
                        <td>{user.id_user}</td>
                        <td>{user.username}</td>
                        <td>{user.email}</td>
                        <td>{user.firstname}</td>
                        <td>{user.lastname}</td>
                        <td>{user.account_type}</td>
                        <td>{user.tutor_email}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default UserFetchAll;