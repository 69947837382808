// components/Layout.js
import React, {useEffect, useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import Loading from "../Loading/Loading";
import MenuDashBoardBtAccueil from "../MenuDashBoard/MenuDashBoardBtAccueil";

/**
 *
 * @returns {Element}
 */
const Layout = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    useEffect(() => {
        // Afficher le chargement au changement de route
        setLoading(true);
        const timer = setTimeout(() => {
            setLoading(false);
        }, 500); // Durée du chargement

        return () => clearTimeout(timer);
    }, [location]);

    return (
        <div className="layout">
            {/* Afficher le composant de chargement si l'état loading est true */}
            {loading && <Loading/>}
            {/* Afficher le menu de navigation si l'état loading est false */}

            {!loading && <MenuDashBoardBtAccueil/>}
            {/* Permet de rendre les routes enfant */}
            {!loading && <Outlet/>}
        </div>
    );
}

export default Layout;