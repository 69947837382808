import ItemForm from "../../../components/forms/items/ItemForm";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import updateData from "../../../components/shared/UpdateData";
import '../../../styles/items/itemUpdate.css';

export const ItemUpdate = () => {
    const location = useLocation();
    const {itemId} = location.state || {};
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    //Modification d'un item de la DB lors de l'envoi du formulaire.
    const handleSubmit = (itemData) => {
        updateData(`${process.env.REACT_APP_API_URL}items/edit`, itemId, itemData);
        navigate('/admin/items');
        //Rafraichissement des donnees pour affichage a jour.
        window.location.reload();
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}items/search/${itemId}`);
                setData(response.data[0]);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [itemId]);

    if (loading) return <p>Chargement...</p>;
    if (error) return <p>Erreur : {error.message}</p>;

    return (
        <div className="itemupdate-container">
            <h1 className="itemupdate-title">
                Gestion des Objets
            </h1>
            <ItemForm data={data} AddData={handleSubmit}/>
        </div>
    )
}