import axios from "axios";


/**
 *
 * @param apiUrl Lien vers l'api
 * @param id id de l'objet a modifier
 * @param data paquet de donnees a envoyer vers l'api
 * @returns {Promise<void>}
 */
//Envoi de l'items modifie vers la DB
const updateData = async (apiUrl, id, data) => {
    const result = await axios.put(`${apiUrl}/${id}`, data);
}

export default updateData;