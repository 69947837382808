import React from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import TeamsFetchAll from '../../pages/BackOffice/teams/TeamsFetchAll';
import TeamsCreation from '../../pages/BackOffice/teams/TeamsCreation';
import TeamsEdit from '../../pages/BackOffice/teams/TeamsEdit';
import TeamsUsers from '../../pages/BackOffice/teams/TeamsUsers';
import TeamQuests from '../../pages/BackOffice/teams/TeamQuests';
import TeamQuestCreation from '../../pages/BackOffice/teams/TeamQuestCreation';
import TeamQuestsFetchAll from '../../pages/BackOffice/teams/TeamQuestsFetchAll';

const TeamsPage = () => {
    return (
        <>
            <div className="menudashboard-sections-container">
                <NavLink to="/admin/teams/create" className="menudashboard-sections-button">+ Nouvelle équipe</NavLink>
                <NavLink to="/admin/teams/" className="menudashboard-sections-button">≡ Liste des équipes</NavLink>
                <NavLink to="/admin/teams/quests/new" className="menudashboard-sections-button">+ Nouvelle quête d'équipe</NavLink>
                <NavLink to="/admin/teams/quests/all" className="menudashboard-sections-button">≡ Liste des quêtes d'équipes</NavLink>
            </div>
            <Routes>
                <Route path="/" element={<TeamsFetchAll />} />
                <Route path="/create" element={<TeamsCreation />} />
                <Route path="/edit/:id" element={<TeamsEdit />} />
                <Route path="/:id/users" element={<TeamsUsers />} />
                <Route path="/:id/quests" element={<TeamQuests />} />
                <Route path="/quests/new" element={<TeamQuestCreation />} />
                <Route path="/quests/all" element={<TeamQuestsFetchAll />} />
            </Routes>
        </>
    );
};

export default TeamsPage;