import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../../styles/quests/createQuest.css';

const CreateQuest = () => {
    const [quest_title, setTitle] = useState('');
    const [quest_description, setDescription] = useState('');
    const [quest_icon, setIcon] = useState('');
    const [quest_difficulty_level, setDifficultyLevel] = useState('');
    const [quest_gold_reward, setGoldReward] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}quests`, {
                quest_title,
                quest_description,
                quest_icon,
                quest_difficulty_level,
                quest_gold_reward,
            });
            console.log('Quest created:', response.data);
            setModalMessage('Quête créée avec succès !');
            setIsError(false);
            setModalOpen(true);


            setTimeout(() => {
                navigate('/admin/quests/list');
            }, 1000);
        } catch (error) {
            console.error('Error creating quest:', error.response.data);
            setModalMessage('Erreur lors de la création de la quête. Veuillez réessayer.');
            setIsError(true);
            setModalOpen(true);
        }
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <div className="create-quest-container">
            <h2 className="create-quest-title">Create Quest</h2>
            <form onSubmit={handleSubmit} className="create-quest-form">
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Title"
                        value={quest_title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <textarea
                        placeholder="Description"
                        value={quest_description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Icon"
                        value={quest_icon}
                        onChange={(e) => setIcon(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Difficulty Level"
                        value={quest_difficulty_level}
                        onChange={(e) => setDifficultyLevel(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="number"
                        placeholder="Gold Reward"
                        value={quest_gold_reward}
                        onChange={(e) => setGoldReward(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="button">Create Quest</button>
            </form>

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <p className={isError ? 'error' : 'success'}>{modalMessage}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreateQuest;