import axios from "axios";
import React, { useEffect, useState } from "react";
import '../../../styles/items/itemFetchAll.css'
import { useNavigate } from "react-router-dom";
import ImageFetcher from "../../../components/shared/images/ImageFetcher";
import {ItemsSearchAndSort} from "../../../components/shared/ItemsSearchAndSort";


const ItemFetchAll = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [sortBy, setSortBy] = useState("name_ASC");


    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [limit, setLimit] = useState(5);


    const [itemFiltred, setFilteredItem] = useState([]);

    //Recuperation et affichage des items contenu dans la DB
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}items/all`);
                setData(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, []);

    // creation de la pagination des element selon le nombre d'element a afficher, et les criteres de recherche
    useEffect(() => {
        const showFilteredItems = (sortedList) => {
            const totalPages = Math.ceil(sortedList.length / limit);
            let filterIndex = 0;
            let pagination = [];

            for (let i = 0; i < totalPages; i++) {
                pagination[i] = [];
                for (let j = 0; j < limit; j++) {
                    if(filterIndex < sortedList.length){
                        pagination[i].push(sortedList[filterIndex]);
                        filterIndex ++;
                    }
                }
            }

            setTotalPages(totalPages);
            setFilteredItem(pagination);

        };
        showFilteredItems(ItemsSearchAndSort(data,searchTerm,sortBy));

    }, [data, limit, sortBy, currentPage, searchTerm]);




    //Suppression d'un element du tableau.
    const handleDelete = (id) => {
        const deleteData = async () => {
            try {
                const result = await axios.delete(`${process.env.REACT_APP_API_URL}items/delete/${id}`);
                return (result);
            } catch (err) {
                console.log(err);
            }
        }
        deleteData();
        alert('item deleted successfully');
        // reload
        window.location.reload();
    }

    //Redirection vers la page de modification d'un element.
    const HandleUpdate = (id) => {
        navigate('update', {state: {itemId: id}});
    };


    // --- Fonctions de pagination --- //

    // Fonction pour passer à la page précédente
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Fonction pour passer à la page suivante
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    if (loading) return <p>chargement...</p>;
    if (error) return <p>Erreur : {error.message}</p>;

    return (
        <div className="itemfetchall-container">
            <div>
                recherche
                <select onChange={(e)=>{
                    setSortBy(e.target.value);
                }}>
                    <option value='name_ASC' defaultValue>nom (Croissant)</option>
                    <option value='name_DESC'>nom (Decroissant)</option>
                    <option value='category'>Categorie</option>
                    <option value='subcategory'>Sous-categorie</option>
                    <option value='accessibility'>Obtention</option>
                    <option value='price_ASC'>Prix (Croissant)</option>
                    <option value='price_DESC'>Prix (Decroissant)</option>
                </select>
                {!sortBy.includes('price') && (
                <input onChange={(e)=>{
                    setSearchTerm(e.target.value);
                }} value={searchTerm}/>

                )}
            </div>
            <table className="itemfetchall-table">
                <thead>
                <tr>
                    <th>Nom</th>
                    <th>Description</th>
                    <th>Catégorie</th>
                    <th>Sous-catégorie</th>
                    <th>Image</th>
                    <th>Obtention</th>
                    <th>Prix</th>
                    <th colSpan={2}>Actions</th>
                </tr>
                </thead>

                <tbody>

                {/*Affichage des items selon les criteres de recheche et mise en pagination*/}
                {itemFiltred.length > 0 ? itemFiltred[currentPage-1].length > 0 && itemFiltred[currentPage-1].map((page, pageIndex) => {
                    return (
                        <tr key={page.id_item}>
                            <td>{page.name}</td>
                            <td>"<i>{page.item_description}</i>"</td>
                            <td>{page.category}</td>
                            <td>{page.subcategory}</td>
                            <td><ImageFetcher apiUrl={`${process.env.REACT_APP_API_URL}items/images`}
                                              imageName={page.image}/></td>
                            <td>{page.accessibility}</td>
                            <td>{page.price} Or</td>
                            <td>
                                <button className="itemfetchall-btn"
                                        onClick={() => HandleUpdate(page.id_item)}>
                                    Modifier
                                </button>
                            </td>
                            <td>
                                <button className="itemfetchall-btn"
                                        onClick={() => handleDelete(page.id_item)}>
                                    Supprimer
                                </button>
                            </td>
                        </tr>
                    )
                }): <tr></tr>}

                </tbody>
            </table>

            {/* Pagination */}
            <div className="objectivesPagination">
                <button onClick={handlePreviousPage} disabled={currentPage === 1}>Précédent</button>
                <span>Page <span id="currentPage">{currentPage}</span> sur {totalPages}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>Suivant</button>
                <div>
                    <label>Nombre a afficher par page : </label>
                    <select onChange={(e) => {
                        setLimit(e.target.value)
                    }}>
                        <option value='5' defaultValue>5</option>
                        <option value='10'>10</option>
                        <option value='25'>25</option>
                    </select>
                </div>
            </div>
        </div>
    )

}

export default ItemFetchAll;